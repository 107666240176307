import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ConfirmButtons from './confirmButtons';

class LibraryCover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmButtonsActive: 0,
    };
    this.confirmDelete = this.confirmDelete.bind(this);
  }
  getPathToProgram() {
    return '/events/' + this.props.event.path + '/program';
  }
  formatDate(d) {
    return moment(d).format('MMM D');
  }
  formatTime(t) {
    return moment(t).format('h:mm A');
  }
  confirmDelete() {
    const id = this.props.event.id;
    var customUser = this.props.app.state.customUser;
    this.props.app.deleteFromLibrary(customUser, id);
    this.props.app.updateUserData(customUser);
  }
  render() {
    return (
      <div className="event-cover">
        {this.props.event &&
          <div onClick={(e) => this.props.history.push(this.getPathToProgram())}>
            <h2>
              {this.props.event.title}
            </h2>
            <p>
              <span className="eventDay">{this.formatDate(this.props.event.startTime)}</span>
              <span className="eventTime">{this.formatTime(this.props.event.startTime)}</span>
            </p>
            <picture>
              <img width="768" alt={this.props.event.title} src={this.props.event.coverPhotoURL} />
            </picture>
          </div>
        }
        
        
        <span className="confirmButtons-neg">
          <ConfirmButtons
            manager={this} 
            active={this.state.confirmButtonsActive} 
            first={'Remove From Library'} 
            second={'Confirm Removal'} 
            callback={this.confirmDelete} />
        </span>
      </div>
    );
  }
}

export default withRouter(LibraryCover);