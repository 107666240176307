import React from 'react';
import moment from 'moment';

class DateInput extends React.Component {
  // Chain of three number inputs to capture a date.
  // Props: manager - push updates to state of this parent form.
  //        name - prefix to add to correctly set manager's state.
  //        initial - start date (optional)
  constructor(props) {
    super(props);
    this.state = {
      month: 1,
      day: 1,
      year: 2020,
    }
  }
  setManagerState(input, val) {
    this.props.manager.setState({
      [this.props.name + input]: val,
    });
  }
  loadDate() {
    var m = moment(this.props.initial);
    this.setState({
      month: m.month() + 1,
      day: m.date(),
      year: m.year(),
    });
  }
  handleChange(e) {
    const val = e.target.type === 'number' ? 
      parseInt(e.target.value) : e.target.value;
    this.setState({
      [e.target.name]: val,
    });
    this.setManagerState(e.target.name, val);
  }
  componentDidMount() {
    if (this.props.initial) {
      this.loadDate();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.initial !== this.props.initial) {
      this.loadDate();
    }
  }
  render() {
    return (
      <>
        <input 
          required 
          id="month" 
          name="month"
          type="number" 
          placeholder="MM" 
          min="1" 
          max="12" 
          value={this.state.month} 
          onChange={(e) => this.handleChange(e)} />
        <input 
          required 
          id="day" 
          name="day" 
          type="number" 
          placeholder="DD" 
          min="1" 
          max="31" 
          value={this.state.day} 
          onChange={(e) => this.handleChange(e)} />
        <input 
          required 
          id="year" 
          name="year"
          type="number" 
          placeholder="YYYY" 
          value={this.state.year} 
          onChange={(e) => this.handleChange(e)} />
      </>
    );
  }
}

export default DateInput;