import React from 'react';

function SponsorsLockup(props) {
  var getListItem = (sponsor) => {
    return (
      <li key={sponsor.id}>
        <a href={sponsor.sponsorURL} target="_blank" rel="noopener noreferrer">
          {sponsor.noLogo ? 
            <p>{sponsor.name}</p>
            :
            <picture>
              <img src={sponsor.imageURL} alt={sponsor.name + ' logo'}/>
            </picture>
          }
        </a>
      </li>
    );
  }
  var getPlatinum = props.sponsors.map((sponsor, key) => {
    if (sponsor.level === 'platinum')
      return getListItem(sponsor);
    else return null;
  });
  var getGold = props.sponsors.map((sponsor, key) => {
	if (sponsor.level === 'gold')
	  return getListItem(sponsor);
	else return null; 
  });
  var getSilver = props.sponsors.map((sponsor, key) => {
    if (sponsor.level === 'silver')
      return getListItem(sponsor);
    else return null;
  });
  var getFoundationAndGrant = props.sponsors.map((sponsor, key) => {
    if (sponsor.level === 'foundationgrant' && !sponsor.noLogo)
      return getListItem(sponsor);
    else return null;
  });
  var getFoundationAndGrantNoLogo = props.sponsors.map((sponsor, key) => {
    if (sponsor.level === 'foundationgrant' && sponsor.noLogo)
      return getListItem(sponsor);
    else return null;
  });
  var getExtraLarge = props.sponsors.map((sponsor, key) => {
    if (sponsor.level === 'donorextralarge') 
      return getListItem(sponsor);
    else return null;
  });
  var getLarge = props.sponsors.map((sponsor, key) => {
    if (sponsor.level === 'donorlarge') 
      return getListItem(sponsor);
    else return null;
  });
  var getMedium = props.sponsors.map((sponsor, key) => {
    if (sponsor.level === 'donormedium') 
      return getListItem(sponsor);
    else return null;
  });
  var getSmall = props.sponsors.map((sponsor, key) => {
    if (sponsor.level === 'donorsmall') 
      return getListItem(sponsor);
    else return null;
  });
  return (
    <>
      {!props.liveMode && 
        <>
          <div className="section-sponsors">
            <h1>Thank You to Our Partners</h1>
            <div className="sponsors-platinum">
              <h2>Platinum Partners</h2>
              <ul>
                {getPlatinum}
              </ul>
            </div>
			<div className="sponsors-gold">
			  <h2>Gold Partners</h2>
			  <ul>
		  	    {getGold}
			  </ul>
			</div>
            <div className="sponsors-silver">
              <h2>Silver Partners</h2>
              <ul>
                {getSilver}
              </ul>
            </div>
            <div className="sponsors-foundation">
              <h2>Foundation & Grant Support</h2>
              <ul>
                {getFoundationAndGrant}
              </ul>
              <ul>
                {getFoundationAndGrantNoLogo}
              </ul>
            </div>
          </div>
          <div className="section-donors">
            <h1>Donors</h1>
            <div className="sponsors-platinum">
              <h2>Luminary Donors ($5,000+)</h2>
              <ul>
                {getExtraLarge}
              </ul>
            </div>
            <div className="sponsors-platinum">
              <h2>$1,000 - $4,999</h2>
              <ul>
                {getLarge}
              </ul>
            </div>
            <div className="sponsors-silver">
              <h2>$500 - $999</h2>
              <ul>
                {getMedium}
              </ul>
            </div>
            <div className="sponsors-foundation">
              <h2>$100 - $499</h2>
              <ul>
                {getSmall}
              </ul>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default SponsorsLockup;