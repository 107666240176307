import React from 'react';
import firebase from '../firebase';
import moment from 'moment';
import Breadcrumbs from './breadcrumbs';
import DateInput from './dateInput';
import TimeInput from './timeInput';
import ConfirmButtons from './confirmButtons';
import TagList from './tagList';
import OfferingsRow from './offeringsRow';

class EventWrite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '', 
      published: false, 
      title: '', 
      locationMain: '', 
      locationDetail: '', 
      geolocationSelect: 0,

      datemonth: '',
      dateday: '',
      dateyear: '',

      starthours: '',
      startminutes: '',
      startampm: 'PM',

      endhours: '', 
      endminutes: '', 
      endampm: 'PM',

      genre: '', 
      description: '', 
      coverPhotoURL: '', 
      accessibility: [], 
      moreInfoURL: '', 
      tags: [], 
      tagSelect: '',
      accessibilitySelect: 'asl',

      confirmButtonsActive: 0,
    }
    this.closeEventWrite = this.closeEventWrite.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.addTag = this.addTag.bind(this);
    this.deleteTag = this.deleteTag.bind(this);
    this.addAccessibility = this.addAccessibility.bind(this);
    this.deleteAccessibility = this.deleteAccessibility.bind(this);
    this.setCoverPhotoURL = this.setCoverPhotoURL.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  closeEventWrite(e) {
    this.props.manager.setState({
      eventWriteActive: false,
      eventToWrite: {},
    });
    this.setState({
      confirmButtonsActive: 0,
    });
  }
  confirmDelete() {
    // grab id
    const id = this.props.manager.state.eventToWrite.id;
    // close event write
    this.closeEventWrite();
    // Delete from storage
    this.props.manager.props.app.clearStorage('events/' + id);
    // delete in database
    const eRef = firebase.database().ref('schedule/' + id);
    eRef.remove();
    // Delete program in database
    const pRef = firebase.database().ref('programs/' + id);
    pRef.remove();
    // delete in front end
    this.props.manager.props.app.deleteEvent(id);
  }
  setCoverPhotoURL(e, url) {
    this.props.app.setState({
      fileBrowserActive: false 
    });
    this.setState({
      coverPhotoURL: url
    });
  }
  formatPath(title) {
    const path = title.replace(/\W/g, '');
    return path;
  }
  loadEventData() {
    var start = moment(this.props.eventToWrite.startTime);
    var end = moment(this.props.eventToWrite.endTime);
    this.setState({
      id: this.props.eventToWrite.id || '', 
      published: this.props.eventToWrite.published || false, 
      title: this.props.eventToWrite.title || '', 
      locationMain: this.props.eventToWrite.locationMain || '', 
      locationDetail: this.props.eventToWrite.locationDetail || '', 

      geolocationSelect: this.props.eventToWrite.useGeolocation || -1,

      datemonth: start.month() + 1 || '',
      dateday: start.date() || '',
      dateyear: start.year() || '',
      starthours: start.hours() || '',
      startminutes: start.minutes() || '',
      startampm: 'PM',
      endhours: end.hours() || '', 
      endminutes: end.minutes() || '', 
      endampm: 'PM',
      genre: this.props.eventToWrite.genre || '', 
      description: this.props.eventToWrite.description || '', 
      coverPhotoURL: this.props.eventToWrite.coverPhotoURL || '', 
      accessibility: this.props.eventToWrite.accessibility || [], 
      moreInfoURL: this.props.eventToWrite.moreInfoURL || '', 
      tags: this.props.eventToWrite.tags || [], 
      tagSelect: this.props.manager.props.app.state.tags[0].name,
    });
  }
  convertTo24Hour(h, a) {
    var hours = h;
    if (hours === 12)
      hours = 0;
    if (a === 'PM')
      hours += 12;
    return hours;
  }
  assembleTime(name) {
    // Create a moment
    var time = moment();
    if (name === 'start') {
      var sh = this.convertTo24Hour(this.state.starthours, this.state.startampm);
      // Set start time data
      time.month(this.state.datemonth - 1).date(this.state.dateday).year(this.state.dateyear)
        .hours(sh).minutes(this.state.startminutes);
    }
    else {
      var eh = this.convertTo24Hour(this.state.endhours, this.state.endampm);
      // Set end time data
      time.month(this.state.datemonth - 1).date(this.state.dateday).year(this.state.dateyear)
        .hours(eh).minutes(this.state.endminutes);
    }
    return time;
  }
  addTag() {
    var tags = [...this.state.tags];
    var includes = false;
    tags.forEach((t) => {
      if (!includes && t.name === this.state.tagSelect)
        includes = true;
    });
    if (!includes) {
      var newTag = this.props.manager.props.app.makeNewTag(this.state.tagSelect);
      tags.push(newTag);
      this.setState({ 
        tags: tags 
      });
    }
  }
  deleteTag() {
    var tags = [...this.state.tags];
    const index = tags.findIndex(t => t.name === this.state.tagSelect);
    if (index >= 0) {
      tags.splice(index, 1);
      this.setState({
        tags: tags
      });
    }
  }
  addAccessibility() {
    var accessibility = [...this.state.accessibility];
    var includes = false;
    accessibility.forEach((a) => {
      if (!includes && a.name === this.state.accessibilitySelect)
        includes = true;
    });
    if (!includes) {
      var newOffering = this.props.manager.props.app.makeNewOffering(this.state.accessibilitySelect);
      accessibility.push(newOffering);
      this.setState({
        accessibility: accessibility
      });
    }
  }
  deleteAccessibility() {
    var accessibility = [...this.state.accessibility];
    const index = accessibility.findIndex(a => a.name === this.state.accessibilitySelect);
    if (index >= 0) {
      accessibility.splice(index, 1);
      this.setState({
        accessibility: accessibility
      });
    }
  }
  handleChange(e) {
    var val;

    switch (e.target.type) {
      case 'number':
        val = parseInt(e.target.value);
        break;
      case 'checkbox':
        val = e.target.checked;
        break;
      default:
        val = e.target.value;
    }

    this.setState({
      [e.target.name]: val,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    // reassemble event data from state
    var startTime = this.assembleTime('start');
    var endTime = this.assembleTime('end');	
    const event = this.props.manager.makeNewEvent(this.props.manager.state.eventToWrite.id, 
      this.state.title, this.state.locationMain, this.state.locationDetail,  
      startTime.format(), endTime.format(), this.state.genre.toLowerCase(), 
      this.state.description, this.state.coverPhotoURL, this.state.accessibility, {}, 
      this.formatPath(this.state.title), this.state.moreInfoURL, this.state.tags, 
      this.state.published, this.state.geolocationSelect);
    // Toasts
    let toasts = [];
    const saveToast = this.props.manager.props.app.makeNewToast('Event data saved.');
    toasts.push(saveToast);
    if (!event.published) {
      const publishToast = this.props.manager.props.app.makeNewToast('Remember to Publish Events to make them public.', 'bg-red', 'Event Unpublished');
      toasts.push(publishToast);
    }
    
    this.props.manager.props.app.pushToast(toasts);
    // set event in database
    const eRef = firebase.database().ref('schedule/' + event.id);
    this.props.manager.updateEventInDatabase(event, eRef);
    // set event in front end
    this.props.manager.props.app.updateEvent(event);
    // Close event write
    this.closeEventWrite();
  }
  componentDidMount() {
    this.props.app.setState({
      fileBrowserData: {
        //context: 'events/' + this.props.manager.state.eventToWrite.id,
        context: 'eventWrite', 
        path: `events/${this.props.manager.state.eventToWrite.id}`, 
        callback: this.setCoverPhotoURL
      }
    });
    this.loadEventData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.eventToWrite !== this.props.eventToWrite && 
      this.props.eventToWrite) {
      this.loadEventData();
    }
  }
  render() {
    var tagOptions = this.props.tagOptions.map((op, key) => {
      return ( 
        <option key={op.name} value={op.name}>{op.name}</option>
      );
    });
    return (
      <div 
        className={this.props.app.state.fileBrowserActive ? 
          'standard-page hiding' : 'standard-page hiding active'}>
        <Breadcrumbs 
        onClick={this.closeEventWrite} 
        innerHTML={'Schedule'} />
        <div id="event-write-inner">
          <h2 className="text-center">Edit Event Data</h2>
          <p>Edit the data for this event here. Nothing will be saved until you click the Save button at the bottom of this form.</p>
          <form onSubmit={this.handleSubmit}>
            <label>
              Title
              <input 
                required 
                id="title" 
                name="title" 
                type="text" 
                placeholder="Grace Jones Live in Concert" 
                value={this.state.title} 
                onChange={this.handleChange} />
            </label>
            <label>
              Published
              <input 
                id="published" 
                name="published" 
                type="checkbox" 
                checked={this.state.published} 
                onChange={this.handleChange} />
              <p>This <span className="term">Event</span> will only be visible to <span className="term">Admins</span> before it is <span className="term">Published</span>.</p>
            </label>
            <label>
              Main Location
              <input 
                required 
                id="location-main" 
                name="locationMain" 
                type="text" 
                placeholder="Ware Center" 
                value={this.state.locationMain} 
                onChange={this.handleChange} />
            </label>
            <label>
              Location Detail
              <input 
                id="location-detail" 
                name="locationDetail" 
                type="text" 
                placeholder="David Bryne Hall" 
                value={this.state.locationDetail} 
                onChange={this.handleChange} />
            </label>

            <label htmlFor="tagSelect">
                Geolocation
                <select 
                  id="geolocationSelect" 
                  name="geolocationSelect" 
                  required 
                  value={this.state.geolocationSelect} 
                  onChange={this.handleChange}>
                  <option value="-1">Off</option>
                  <option value="0">Ware Center</option>
                  <option value="1">Winter Center</option>
                </select>
              </label>

            <label className="date-inputs">
              Date
              <span>
              <DateInput 
                manager={this} 
                initial={this.props.eventToWrite.startTime} 
                name="date" />
            </span>
            </label>
            <label className="date-inputs">
              Start Time
              <span>
                <TimeInput 
                  manager={this} 
                  initial={this.props.eventToWrite.startTime} 
                  name="start" />
              </span>
            </label>
            <label className="date-inputs">
              Estimated End Time
              <span>
                <TimeInput 
                  manager={this} 
                  initial={this.props.eventToWrite.endTime}
                  name="end" />
              </span>
            </label>
            <label>
              Genre
              <input 
                required 
                id="genre" 
                name="genre" 
                type="text" 
                placeholder="Pop" 
                value={this.state.genre} 
                onChange={this.handleChange} />
            </label>
            
            <section>
              <label>
                Event Tags
                {this.state.tags.length > 0 ? 
                  <TagList tags={this.state.tags} />
                  :
                  <ul>
                    <li>(No tags)</li>
                  </ul>
                }
              </label>
              <label htmlFor="tagSelect">
                Select Tag
                <select 
                  id="tagSelect" 
                  name="tagSelect" 
                  required 
                  value={this.state.tagSelect} 
                  onChange={this.handleChange}>
                  {tagOptions}
                </select>
              </label>
              <ul className="flex-row">
                <li>
                  <button 
                    type="button" 
                    className="cta cta-black" 
                    onClick={this.addTag}>
                    Add Tag
                  </button>
                </li>
                <li>
                  <button 
                    type="button" 
                    className="cta cta-black" 
                    onClick={this.deleteTag}>
                    Remove Tag
                  </button>
                </li>
              </ul>
            </section>

            <section>
              <label>
                Accessibility Offerings
                {this.state.accessibility.length > 0 ? 
                  <OfferingsRow offerings={this.state.accessibility} />
                  :
                  <ul>
                    <li>(Nothing)</li>
                  </ul>
                }
              </label>
              <label htmlFor="tagSelect">
                Select Offering
                <select 
                  id="accessibilitySelect" 
                  name="accessibilitySelect" 
                  required 
                  value={this.state.accessibilitySelect} 
                  onChange={this.handleChange}>
                  <option value="asl">ASL Interpretation</option>
                  <option value="audiodescription">Audio Description</option>
                  <option value="opencaptioning">Open Captioning</option>
                  <option value="sensoryfriendly">Sensory Friendly</option>
                </select>
              </label>
              <ul className="flex-row">
                <li>
                  <button 
                    type="button" 
                    className="cta cta-black" 
                    onClick={this.addAccessibility}>
                    Add Offering
                  </button>
                </li>
                <li>
                  <button 
                    type="button" 
                    className="cta cta-black" 
                    onClick={this.deleteAccessibility}>
                    Remove Offering
                  </button>
                </li>
              </ul>
            </section>
            
            <label>
              Description
              <textarea 
                required 
                id="description" 
                name="description" 
                type="text" 
                placeholder="A single paragraph description of the event" 
                value={this.state.description} 
                onChange={this.handleChange} />
            </label>
            <label id="profPictureAdd">
              Cover Photo
              {this.state.coverPhotoURL.length > 0 ? 
                <div>
                  <img height="75px" src={this.state.coverPhotoURL} alt={'Thumbnail of ' + this.state.coverPhotoURL} />
                  <button 
                    className="cta cta-trans button-icon prof-pic-add" 
                    type="button" 
                    onClick={(e) => this.props.app.setState({fileBrowserActive: true})}>
                    Open File Browser
                  </button>
                </div>
                :
                <div>
                  <button 
                    id="profAddPic"
                    className="cta cta-trans button-icon prof-pic-add" 
                    type="button" 
                    onClick={(e) => this.props.app.setState({fileBrowserActive: true})}>
                    Open File Browser
                  </button>
                </div>
              }
            </label>
            <label>
              More Info URL
              <input 
                id="moreInfoURL" 
                name="moreInfoURL" 
                type="text" 
                placeholder="https://artsmu.com/this-event" 
                value={this.state.moreInfoURL} 
                onChange={this.handleChange} />
            </label>

            <input 
              id="submit" 
              name="submit" 
              type="submit" 
              value="Save" />
              <span className="confirmButtons-neg">
                <ConfirmButtons 
                  manager={this} 
                  active={this.state.confirmButtonsActive} 
                  first={'Delete Event'} 
                  second={'Confirm Deletion'} 
                  callback={this.confirmDelete} />
              </span>
          </form>
        </div>
      </div>
    );
  }
}

export default EventWrite;