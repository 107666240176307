import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import ResetScroll from './lib/resetScroll';
import Updater from './lib/updater';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ResetScroll>
        <App />
      </ResetScroll>
    </Router>
    <Updater />
  </React.StrictMode>,
  document.getElementById('root')
);

// Removing default service worker register call
// Service worker will be registered in Updater component
//serviceWorker.register();
