import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import EventInfo from "./eventInfo";
import EventFeature from "./eventFeature";

function EventsList(props) {
  const [upcomingLocal, setUpcomingLocal] = React.useState([]);
  const [pastLocal, setPastLocal] = React.useState([]);

  

  /*var eventsList = props.schedule.map((event, key) => {
    var now = moment();
    if (!moment(event.endTime).isBefore(now)) {
      if (props.write || event.published) {
        return (
          <li key={event.id} className="schedule-event">
            <EventInfo
              app={props.manager.props.app}
              manager={props.manager}
              event={event}
              expand={props.match.params.eventPath === event.path}
              bookmarked={
                props.manager.props.app.state.customUser
                  ? isBookmarked(event)
                  : false
              }
              write={props.write}
            />
          </li>
        );
      } else return null;
    } else return null;
  });

  var pastList = props.schedule.map((event, key) => {
    var seasonStart = moment().year(2021).month("August").day(27).hour(0);
    var now = moment();
    if (
      moment(event.endTime).isBefore(now) &&
      moment(event.endTime).isAfter(seasonStart)
    ) {
      if (props.write || event.published) {
        return (
          <li key={event.id} className="schedule-event">
            <EventInfo
              app={props.manager.props.app}
              manager={props.manager}
              event={event}
              expand={props.match.params.eventPath === event.path}
              bookmarked={
                props.manager.props.app.state.customUser
                  ? isBookmarked(event)
                  : false
              }
              write={props.write}
              past
            />
          </li>
        );
      } else return null;
    } else return null;
  });
  
  */

  React.useEffect(() => {

    var isBookmarked = (event) => {
      var result = false;
      if (props.manager.props.app.state.customUser.bookmarks) {
        const bookmarks =
          props.manager.props.app.state.customUser.bookmarks || [];
        result = bookmarks.includes(event.id);
      }
      return result;
    };

    var eventsList = props.schedule.map((event, key) => {
      var now = moment();
      if (!moment(event.endTime).isBefore(now)) {
        if (props.write || event.published) {
          return (
            <li key={event.id} className="schedule-event">
              <EventInfo
                app={props.manager.props.app}
                manager={props.manager}
                event={event}
                expand={props.match.params.eventPath === event.path}
                bookmarked={
                  props.manager.props.app.state.customUser
                    ? isBookmarked(event)
                    : false
                }
                write={props.write}
              />
            </li>
          );
        } else return null;
      } else return null;
    });
    setUpcomingLocal(eventsList.filter((n) => n));

    var pastList = props.schedule.map((event, key) => {
      var seasonStart = moment().year(2022).month("September").day(1).hour(0);
      var now = moment();
      if (
        moment(event.endTime).isBefore(now) &&
        moment(event.endTime).isAfter(seasonStart)
      ) {
        if (props.write || event.published) {
          return (
            <li key={event.id} className="schedule-event">
              <EventInfo
                app={props.manager.props.app}
                manager={props.manager}
                event={event}
                expand={props.match.params.eventPath === event.path}
                bookmarked={
                  props.manager.props.app.state.customUser
                    ? isBookmarked(event)
                    : false
                }
                write={props.write}
                past
              />
            </li>
          );
        } else return null;
      } else return null;
    });
    setPastLocal(pastList.filter((n) => n));
  }, [props.schedule, props.manager, props.match.params.eventPath, props.write]);

  return (
    <div>
      {!props.write &&
        props.featuredEvent &&
        props.featuredEvent.published === true && (
          <EventFeature
            app={props.manager.props.app}
            event={props.featuredEvent}
          />
        )}
      <div className="section-skewed bg-gold">
        <h1>Upcoming Events</h1>
        {upcomingLocal.length > 0 ? 
          <ul className="schedule">{upcomingLocal}</ul>
          :
          <p className="centered">Nothing yet.</p>
        }
      </div>
      <div className="section-skewed bg-stark">
        <h1>Earlier This Season</h1>
        {pastLocal.length > 0 ?
          <ul className="schedule">{pastLocal}</ul>
          :
          <p className="centered">Nothing yet.</p>
        }
      </div>
    </div>
  );
}

export default withRouter(EventsList);
