import React from 'react';

class ConfirmButtons extends React.Component {
  handleClick(val) {
    console.log(this.props);

//  expand event modal to class component ?

    if (val === 0) {
      this.props.manager.setState({
        confirmButtonsActive: 1,
      });
      return;
    }
    else {
      this.props.manager.setState({
        confirmButtonsActive: 0,
      });
      return this.props.callback();
    }
  }
  
  render() {
    return (
      <>
        <button 
          className={this.props.active === 0 ? 'cta hiding active' : 'cta hiding'} 
          type="button" 
          onClick={(e) => this.handleClick(0)}>
          {this.props.first}
        </button>
        <button 
          className={this.props.active === 1 ? 'cta hiding active' : 'cta hiding'} 
          type="button" 
          onClick={(e) => this.handleClick(1)}>
          {this.props.second}
        </button>
      </>
    );
  }
} 

export default ConfirmButtons;