import React from 'react';
import { withRouter } from 'react-router-dom';

class DeleteAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    }
  }
  pushToHistory(e, path) {
    const history = this.props.history;
    history.push(path);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    const path = 'profilePics/' + this.props.app.state.customUser.uid;
    this.props.app.clearStorage(path);
    this.props.app.reauthenticateThenDelete(
      this.state.email, 
      this.state.password
    );
  }
  render() {
    return (
      <div className="standard-page">
        <h1>Delete Account</h1>
        <h2>Are you sure you wish to delete your account?</h2>
        <p>Deleting your account will result in the irreversible loss of data associated with your profile, however you will still be able to read Programs. If you want to permanently delete your account, start by entering your credentials below.</p>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <label>
            Email
            <input 
              required 
              id="email" 
              name="email" 
              type="text" 
              placeholder="your@email.address" 
              value={this.state.email} 
              onChange={(e) =>this.handleChange(e)} />
          </label>
          <label>
            Password
            <input 
              required 
              id="password" 
              name="password" 
              type="password" 
              placeholder="your password" 
              value={this.state.password} 
              onChange={(e) => this.handleChange(e)} />
          </label>
          <input 
            className="cta cta-neg"
            id="submit" 
            name="submit" 
            type="submit" 
            value="Confirm Deletion" />
        </form>
        <button className="cta" onClick={(e) => this.pushToHistory(e, '/profile/settings')}>Keep Account</button>
      </div>
    );
  }
}

export default withRouter(DeleteAccount);