import React from 'react'
import firebase from '../firebase';
import Breadcrumbs from './breadcrumbs';
import ConfirmButtons from './confirmButtons';

class SponsorWrite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      level: 'platinum',
      sponsorURL: '',
      imageURL: '',

      confirmButtonsActive: 0,
    };
    this.closeSponsorWrite = this.closeSponsorWrite.bind(this);
    this.setImageURL = this.setImageURL.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }
  closeSponsorWrite(e) {
    window.scrollTo(0, 0);
    this.props.manager.setState({
      sponsorWriteActive: false,
      sponsorToWrite: {},
    });
  }
  confirmDelete() {
    // grab id
    const id = this.props.manager.state.sponsorToWrite.id;
    // close event write
    this.closeSponsorWrite();
    // delete in database
    const sRef = firebase.database().ref('sponsors/' + id);
    sRef.remove();
    // delete in front end
    this.props.manager.props.app.deleteSponsor(id);
  }
  setImageURL(e, url) {
    this.setState({
      imageURL: url
    });
    this.props.app.setState({
      fileBrowserActive: false
    });
  }
  loadSponsor() {
    this.setState({
      name: this.props.sponsorToWrite.name || '_blank', 
      level: this.props.sponsorToWrite.level || '', 
      sponsorURL: this.props.sponsorToWrite.sponsorURL || '', 
      imageURL: this.props.sponsorToWrite.imageURL || '', 
      noLogo: this.props.sponsorToWrite.noLogo || false,
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    // reassemble sponsor data from state
    const sponsor = this.props.manager.makeNewSponsor(
      this.props.manager.state.sponsorToWrite.id,
      this.state.name, 
      this.state.level,
      this.state.sponsorURL, 
      this.state.imageURL, 
      this.state.imageURL.length > 1 ? false : true,
    );
    // Toast
    const toast = this.props.manager.props.app.makeNewToast('Partner data saved.');
    this.props.manager.props.app.pushToast([toast]);
    // set sponsor in database & front end
    const sRef = firebase.database().ref('sponsors/' + sponsor.id);
    this.props.manager.updateSponsorInDatabase(sponsor, sRef);
    this.props.manager.props.app.updateSponsorInApp(sponsor);
    // close sponsorWrite
    this.closeSponsorWrite();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.app.setState({
      fileBrowserData: {
        //context: 'sponsors/',
        context: 'sponsorWrite',
        path: 'sponsors',
        callback: this.setImageURL
      }
    });
    this.loadSponsor();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sponsorToWrite !== this.props.sponsorToWrite && 
      this.props.sponsorToWrite) {
      this.loadSponsor();
    }
  }
  render() {
    return (
      <div 
        id="sponsor-write"
        className={this.props.active ? 'hiding active standard-page' : 'hiding standard-page'}>
        <Breadcrumbs onClick={this.closeSponsorWrite} innerHTML={'Sponsors'}/>
          <h2 className="text-center">Edit Sponsor Data</h2>
          <p>Edit data for your <span className="term">Sponsor</span> here. Nothing will be saved until you click the Save button below.</p>
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <label htmlFor="name">
              Partner Name
              <input 
                required 
                id="name" 
                name="name" 
                type="text" 
                placeholder="Garcia Guitar Repairs" 
                value={this.state.name} 
                onChange={(e) => this.handleChange(e)} />
            </label>
            <label htmlFor="level">
              Classification
                <select 
                  required 
                  id="level"
                  name="level" 
                  value={this.state.level} 
                  onChange={(e) => this.handleChange(e)}>
                  <option value="platinum">Platinum Partner</option>
                  <option value="silver">Silver Partner</option>
				  <option value="gold">Gold Partner</option>
                  <option value="foundationgrant">Foundation & Grant Support</option>
                  <option value="donorextralarge">Donor $5k+</option>
                  <option value="donorlarge">Donor $1k+</option>
                  <option value="donormedium">Donor $500+</option>
                  <option value="donorsmall">Donor $100+</option>
                </select>
            </label>
            <label htmlFor="sponsorURL">
              Partner Website
              <input 
                id="sponsorURL" 
                name="sponsorURL" 
                type="url" 
                placeholder="https://www.website.com" 
                value={this.state.sponsorURL} 
                onChange={(e) => this.handleChange(e)} />
            </label>
            <label id="profPictureAdd">
              Partner Logo
              {this.state.imageURL.length > 0 ? 
                <div>
                  <img height="75px" src={this.state.imageURL} alt={'Thumbnail of ' + this.state.imageURL} />
                  <button 
                    className="cta cta-neg cta-tool cta-small"
                    type="button" 
                    onClick={(e) => this.setState({imageURL: ''})}>
                    Clear Partner Logo
                  </button>
                  <button
                    className="cta cta-trans button-icon prof-pic-add"
                    type="button" 
                    onClick={(e) => this.props.app.setState({ fileBrowserActive: true })}>
                    Open File Browser
                  </button>
                </div>
                :
                <div>
                  <button
                    className="cta cta-trans button-icon prof-pic-add"  
                    type="button" 
                    onClick={(e) => this.props.app.setState({ fileBrowserActive: true })}>
                    Open File Browser
                  </button>
                </div>
              }
            </label>
            <input 
              id="submit"
              className="cta cta-lg"
              name="submit"
              type="submit" 
              value="Save" />
            <span className="confirmButtons-neg">
            <ConfirmButtons
              manager={this}
              active={this.state.confirmButtonsActive} 
              first={'Delete Partner'} 
              second={'Confirm Deletion'} 
              callback={this.confirmDelete} />
            </span>
          </form>
      </div>
    );
  }
}

export default SponsorWrite;