import React from 'react';
import moment from 'moment';

class TimeInput extends React.Component {
  // Chain of two number inputs and a select input to capture a time. 
  // Props: manager - push updates to state of this parent form.
  //        name - prefix to add to correctly set manager's state.
  constructor(props) {
    super(props);
    this.state = {
      hours: 12,
      minutes: 30,
      ampm: 'AM',
    };
  }
  setManagerState() {
    this.props.manager.setState({
      [this.props.name + "hours"]: this.state.hours, 
      [this.props.name + "minutes"]: this.state.minutes, 
      [this.props.name + "ampm"]: this.state.ampm, 
    });
  }
  convertTo12Hour(h) {
    var hours = h;
    if (hours === 0)
      hours = 12;
    if (hours > 12) {
      hours -= 12;
    }
    return hours;
  }
  loadTime() {
    const m = moment(this.props.initial);
    var hrs = this.convertTo12Hour(m.hours());
    var mins = m.minutes();
    var ampm = m.hours() >= 12 ? 'PM' : 'AM';
    mins = mins < 10 ? '0' + mins : mins;
    this.setState({
      hours: hrs,
      minutes: mins,
      ampm: ampm,
    }, this.setManagerState);
  }
  handleChange(e) {
    const val = e.target.type === 'number' ? 
      parseInt(e.target.value) : e.target.value;
    this.setState({
      [e.target.name]: val,
    }, this.setManagerState);
  }
  componentDidMount() {
    if (this.props.initial) {
      this.loadTime();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.initial && prevProps.initial !== this.props.initial) {
      this.loadTime();
    }
  }
  render() {
    return(
      <>
        <input 
          required 
          id="hours" 
          name="hours" 
          type="number" 
          placeholder="12" 
          min="1" 
          max="12" 
          value={this.state.hours} 
          onChange={(e)=>this.handleChange(e)} />
        <input 
          required 
          id="minutes" 
          name="minutes" 
          type="number" 
          placeholder="30" 
          min="0" 
          max="59" 
          value={this.state.minutes} 
          onChange={(e)=>this.handleChange(e)} />
        <select 
          required 
          id="ampm"
          name="ampm" 
          value={this.state.ampm} 
          onChange={(e) => this.handleChange(e)}>
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </>
    );
  }
}

export default TimeInput;