import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import firebase, { auth } from './firebase';
import moment from 'moment';
import { getMessagingAndToken } from './pushNotifications';
import PatronApp from './patronApp';
import Modal from './lib/modal';
import Toolbar from './lib/toolbar';
import Toaster from './lib/toaster';
import Footer from './lib/footer';
import About from './lib/about';
import SignUp from './lib/signUp';
import LogIn from './lib/logIn';
import ScheduleRead from './lib/scheduleRead';
import ResetPassword from './lib/resetPassword';
import DeleteConfirm from './lib/deleteConfirm';
import FinePrint from './lib/finePrint';
import FileUpload from './lib/fileUpload';
import Onboarding from './lib/onboarding';
import BadgeReport from './lib/badgeReport';
import Leaderboard from './lib/leaderboard';
import FileBrowser from './lib/fileBrowser';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      customUser: null,
      newBirthday: null,
      schedule: [],
      sponsors: [],
      programs: [],
      tags: [], 
      featuredEvent: null,
      timeCheckTimeout: null, 
      transition: false, 
      liveMode: false,
      messaging: null, 
      currentToken: null, 
      toastActive: null, 
      toastQueue: [], 
      savedCheckIn: null,
      allCustomUsers: [],
      logInSpinnerActive: false,
      badgeReportActive: false,
      fileBrowserActive: false, 
      fileBrowserData: {
        context: null,
        path: null,
        callback: null,
      },
      modalActive: false,
      modalData: {
        heading: 'A small problem',
        paragraph: '',
        cta: 'OK',
      },
    };
    this.logIn = this.logIn.bind(this);
    this.logOut = this.logOut.bind(this);
  }
  logIn(email, password, caller) {
    // Use Firebase email sign in provider
    auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.setState({
          logInSpinnerActive: false,
        });
        /* Grab User object from result
        //const user = result.user;
        // Grab CustomUser object from database
        //this.getCustomUserData(user, 'from logIn()');
        // Cache User and CustomUser objects in state
        //this.setState({
          user
        });*/
        this.pushToHistory('/profile');
    })
    .catch((error) => {
      this.setState({
        logInSpinnerActive: false,
      });
      caller.handleError(error);
    });
  }
  logOut() {
    auth.signOut()
      .then(() => {
        // Toast
        const toast = this.makeNewToast('You have logged out.');
        this.pushToast([toast]);
        this.setState({
          user: null,
          customUser: null,
        });
      });
  }
  getCustomUserData(user, from = '') {
    // Derive ref from uID
    const customUserRef = firebase.database().ref('users/' + user.uid);
    // Pull data from database using ref
    customUserRef.once('value')
			.then((snapshot) => {
        const snapshotVal = snapshot.val();
        // If there is user data
        if (snapshotVal !== null) {
          var customUser = this.rebuildCustomUser(snapshotVal);
          // Push notifications
          if (firebase.messaging.isSupported()) {
            if (this.state.messaging) {
              //console.log('have messaging obj');
            }
            else {
              //console.log('dont have messaging obj yet');
              this.initMessaging(customUser);
            }
          }
          if (this.state.currentToken) {
            this.sendTokenToServer(this.state.currentToken, customUser);
          }
          const now = moment().format();
          customUser.lastLogIn = now;
          /*console.log(customUser.badges);
          if (customUser.badges.length < 1) {
            const firstBadge = this.makeNewBadge(2, moment().format());
            this.giveBadge(firstBadge, customUser);
          }*/
          // will this create the race condition w/ next function?
          this.handleBadgesOnLogIn(customUser);
          this.handleSavedCheckIn(customUser);
          /// this line pushes old data !!!!
          //this.updateUserData(customUser, 'from getCustomUserData()');
        }
        // Else if there is not user data
        else {
          // Post new user data
          var newCustomUser = this.postNewUserData(user, customUserRef);
          this.handleSavedCheckIn(newCustomUser);
          //this.updateUserData(newCustomUser);
        }
      });
      
  }

  handleSavedCheckIn(customUser) {
    // If theres a saved check in
    if (this.state.savedCheckIn !== null) {
      this.setState({
        savedCheckIn: null
      });
      if (!customUser.attendance.includes(this.state.savedCheckIn)) {
        // if we have schedule data
        if (this.state.schedule && this.state.schedule.length > 0) {
          // find index of saved check in
          let index = this.state.schedule.findIndex(ev => ev.eventID === this.state.savedCheckIn);
          if (index >= 0) {
            // get start time of saved check in
            let startTime = moment().format(this.state.schedule[index].startTime);
            this.checkIn(this.state.savedCheckIn, customUser, startTime);
          }
        }
      }
    }
    // else just update the user data
    else {
      this.updateUserData(customUser, 'from handleSavedCheckIn no data');
    }
  }

  // CustomUser C.R.U.D.
  // Create
  postNewUserData(user, customUserRef) {
    const birthday = this.state.newBirthday || null;
    const defaultDisplayName = user.email.substring(0, user.email.indexOf("@"));
    console.log("default", defaultDisplayName);
    const newCustomUser = this.makeNewCustomUser(
      user.uid, false, defaultDisplayName, user.email, 
      'https://firebasestorage.googleapis.com/v0/b/ware-421.appspot.com/o/prof-pic-grey.png?alt=media&token=d6548615-aa9c-486f-a2d0-3f3e2b30fd41', 
      '', [], [], [], [], '', birthday, 
      moment().format(), null, 0, 0, [], [], null, 0, 
      false
    );
    customUserRef.set(newCustomUser);
    return newCustomUser;
  }
  // Read
  readUserData(uid) {
    const customUserRef = firebase.database().ref('users/' + uid);
    // Read user data once
    customUserRef.once('value')
      .then((snapshot) => {
        // Grab the data snapshot
        const snapshotVal = snapshot.val();
        // Rebuild CustomUser object from snapshot value
        const customUser = this.rebuildCustomUser(snapshotVal);
        // Return CustomUser object
        return customUser;
      });
  }
  readAllUsersData() {
    const uRef = firebase.database().ref('users');
    uRef.once('value')
      .then((snapshot) => {
        var allUsers = this.snapshotToArray(snapshot);
        var rebuilt = [];
        allUsers.forEach(u => {
          const rebuiltUser = this.rebuildCustomUser(u);
          rebuilt.push(rebuiltUser);
        });
        this.setState({
          allCustomUsers: rebuilt,
        });
      });
  }
  // Update
  updateUserData(customUser, memo = '') {
    // write to database
    const customUserRef = firebase.database().ref('users/' + customUser.uid);
    customUserRef.set(customUser);
    // update in front end
    this.setState({
      customUser
    });
  }

  // CustomUser Utils
  makeNewCustomUser(uid, admin, displayName, email, photoURL, 
    fullName, bookmarks, tickets, library, attendance, tokens, birthday, 
    lastLogIn, deleted, points, tier, badges, badgeQueue, feedback, liveModeCount, 
    setProfPic) {
    const customUser = {
      uid, admin, displayName, email, photoURL, fullName, 
      bookmarks, tickets, library, attendance, tokens, birthday, 
      lastLogIn, deleted, points, tier, badges, badgeQueue, feedback, liveModeCount, 
      setProfPic
    };
    return customUser;
  }
  rebuildCustomUser(userData) {
    var lli;
    if (userData.lastLogIn)
      lli = moment(userData.lastLogIn).format();
    var customUser = {
      uid: userData.uid || null,
      admin: userData.admin || false,
      displayName: userData.displayName || null,
      email: userData.email || null,
      photoURL: userData.photoURL || null,
      fullName: userData.fullName || null,
      bookmarks: userData.bookmarks || [],
      library: userData.library || [], 
      attendance: userData.attendance || [], 
      tokens: userData.tokens || [], 
      birthday: userData.birthday || null, 
      lastLogIn: lli || null, 
      deleted: userData.deleted || null, 
      points: userData.points || 0, 
      tier: userData.tier || 0, 
      badges: userData.badges || [], 
      badgeQueue: userData.badgeQueue || [],
      feedback: userData.feedback || null, 
      liveModeCount: userData.liveModeCount || [], 
      setProfPic: userData.setProfPic || false, 
    };
    return customUser;
  }

  // Event Schedule
  // Read full schedule
  readAllEventData() {
    const eventRef = firebase.database().ref('schedule');
    // Read event data once
    eventRef.once('value')
      .then((snapshot) => {
        // Grab the data snapshot
        const snapshotVal = this.snapshotToArray(snapshot);
        // Rebuild Events from snapshot
        var schedule = snapshotVal.map((e) => this.rebuildEvent(e));
        // Sort events by start Time
        schedule.sort((a, b) => {
          // Compare function
          var ma = moment(a.startTime).valueOf();
          var mb = moment(b.startTime).valueOf();
          return ma - mb;
        });
        this.setState({
          schedule
        });
        this.checkCurrentTime();
      });
  }
  // Update event in front end
  updateEvent(event) {
    let schedule = this.state.schedule;
    const index = this.state.schedule.findIndex(e => e.id === event.id);
    schedule[index] = event;
    this.setState({
      schedule
    });
  }
  // Update event in database
  updateEventInDatabase(event, eventRef) {
    eventRef.set(event);
  }
  // Delete event in front end
  deleteEvent(id) {
    // Delete in schedule
    var schedule = [...this.state.schedule];
    const index = schedule.findIndex(e => e.id === id);
    schedule.splice(index, 1);
    // delete in programs
    let programs = [...this.state.programs];
    const pIndex = programs.findIndex(p => p.id === id);
    if (pIndex >= 0)
      programs.splice(pIndex, 1);
    // update in state
    this.setState({
      schedule,
      programs
    });
  }
  // Rebuild Event object from partial database entry
  rebuildEvent(eventData) {
    const event = {
      id: eventData.id || null,
      title: eventData.title || null,
      locationMain: eventData.locationMain || null,
      locationDetail: eventData.locationDetail || null,
      startTime: moment(eventData.startTime).format() || null,
      endTime: moment(eventData.endTime).format() || null,
      genre: eventData.genre || null,
      description: eventData.description || null,
      coverPhotoURL: eventData.coverPhotoURL || null,
      accessibility: eventData.accessibility || [],
      program: eventData.program || {}, 
      path: eventData.path || null, 
      moreInfoURL: eventData.moreInfoURL || null, 
      tags: eventData.tags || [], 
      published: eventData.published || false, 
      useGeolocation: eventData.useGeolocation || -1,
	  programAvailable: eventData.programAvailable || true
    };
    return event;
  }
  checkCurrentTime() {
    var now = moment();
    // Check every event
    this.state.schedule.forEach((e) => {
      // grab event start time
      var start = moment(e.startTime);
      var end = moment(e.endTime);
      // Lights out one minute before showtime
      var lightsOutTime = moment(start).subtract(1, 'minute');
      // Set featured event if less than 24 hours away & today
      const difference = Math.abs(now.diff(start, 'hours'));
      if (difference < 24 && now.date() === start.date()) {
        this.setState({
          featuredEvent: e
        });
        // Grab mintues away from start time
        var mins = Math.abs(now.diff(start, 'minutes'));
        // Start checking time 30 minutes before start
        if (now.isBefore(lightsOutTime) && mins < 31 && !this.state.liveMode) {
          this.setTimeCheckTimeout();
        }
        else if (now.isBetween(lightsOutTime, end)) {
          this.setTimeCheckTimeout();
        }
        /*else {
          console.log('not in any time check condition');
        }*/
        // Set live mode
        var liveMode = false;
        if (now.isBetween(lightsOutTime, end)) {
          // Only toast once
          if (!this.state.transition) {
            const showToast = this.makeNewToast('It\'s showtime!', 'bg-gold');
            const politeToast = this.makeNewToast('Please silence your cell phone and only check the Program when necessary.');
            this.pushToast([showToast, politeToast]);
            this.setState({
              transition: true
            });
          }
          liveMode = true;
          /*if (this.state.timeCheckTimeout) {
            this.clearTimeCheckTimeout();
          }*/
          if (this.state.customUser) {
            var customUser = this.state.customUser;
            if (!customUser.liveModeCount.includes(e.id)) {
              customUser.liveModeCount.push(e.id);
              this.handleLiveModeBadges(customUser);
              this.updateUserData(customUser);
            }
          }
        }
        else if (now.isAfter(end)) {
          liveMode = false;
          if (this.state.timeCheckTimeout) {
            this.clearTimeCheckTimeout();
          }
        }
        
        this.setState({
          liveMode: liveMode
        });
      }
    });
  }
  setTimeCheckTimeout() {
    // If there's an old timeout set, clear it
    if (this.state.timeCheckTimeout) {
      var oldT = this.state.timeCheckTimeout;
      clearTimeout(oldT);
    }
    // Set timeout---check time every 5 seconds
    var t = setTimeout(() => {
      //if (!this.state.liveMode)
        this.checkCurrentTime();
    }, 5000);
    this.setState({
      timeCheckTimeout: t
    });
  }
  clearTimeCheckTimeout() {
    var t = this.state.timeCheckTimeout;
    clearTimeout(t);
    this.setState({
      timeCheckTimeout: null
    });
  }

  // Tags
  // Read all tags
  makeNewTag(name) {
    const tag = {
      name: name
    };
    return tag;
  }
  readAllTagData() {
    const tRef = firebase.database().ref('tags');
    // Read tag data once
    tRef.once('value')
      .then((snapshot) => {
        const snapshotVal = this.snapshotToArray(snapshot);
        const tags = snapshotVal.map(t => this.rebuildTag(t));
        this.setState({ 
          tags 
        });
      });
  }
  rebuildTag(tagData) {
    const n = tagData.name ? tagData.name : tagData;
    const tag = {
      name: n || '_blank'
    };
    return tag;
  }
  postNewTag(name, tagRef) {
    const newTag = this.makeNewTag(name);
    const t = [...this.state.tags];
    t.push(newTag);
    tagRef.set(t);
    this.setState({
      tags: t,
    });
    return newTag;
  }
  removeTagFromSchedule(name) {
    var removed = 0;
    var schedule = [...this.state.schedule];
    schedule.forEach((event) => {
      var present = false;
      event.tags.forEach((tag) => {
        if (tag.name === name) {
          present = true;
        }
      });
      if (present) {
        const index = event.tags.findIndex(t => t.name === name);
        var newTags = [...event.tags];
        newTags.splice(index, 1);
        event.tags = newTags;
        const eRef = firebase.database().ref('/schedule/' + event.id);
        this.updateEventInDatabase(event, eRef);
        removed++;
      }
    });
    // Compose toast body
    var toastBody = 'Tag [' + name + '] deleted';
    if (removed > 0) {
       toastBody += ' & removed from ' + removed + ' Event';
      if (removed > 1)
        toastBody += 's';
      toastBody += '.';
      // Make and push toast
      const toast = this.makeNewToast(toastBody);
      this.pushToast([toast]);
      this.setState({
        schedule: schedule
      });
    }
  }
  updateTagData(tags, tRef) {
    tRef.set(tags);
    this.setState({
      tags: tags,
    });
  }
  
  // Badges
  makeNewBadge(id, timestamp) {
    const newBadge = {
      id: id,
      timestamp: timestamp
    }
    return newBadge;
  }
  giveBadge(badge, customUser, forceReport = true) {
    var badges = [...customUser.badges];
    var badgeQueue = [...customUser.badgeQueue];
    badges.push(badge);
    badgeQueue.push(badge);
    badges.sort((a, b) => {
      var at = moment(a.timestamp).valueOf();
      var bt = moment(b.timestamp).valueOf();
      return at - bt;
    });
    badges.reverse();
    customUser.badges = badges;
    customUser.badgeQueue = badgeQueue;
    this.givePoints(25, customUser, 'New Badge');
    this.setState({
      badgeReportActive: forceReport,
    });
  }
  deleteAllBadges(customUser) {
    var badges = [];
    var badgeQueue = [];
    customUser.badges = badges;
    customUser.badgeQueue = badgeQueue;
    this.updateUserData(customUser); // OK for testing purposes only
  }
  hasBadge(customUser, badgeID) {
    var repeat = false;
    customUser.badges.forEach((b) => {
      if (b.id === badgeID) {
        repeat = true;
      }
    });
    return repeat;
  }
  // Handle badges on log in
  handleBadgesOnLogIn(customUser) {
    var anyBadges = false;
    var now = moment();
    // Is today the user's birthday?
    if (this.isBirthday(customUser, now)) {
      // Check they've not gotten this years bday badge yet
      var repeat = this.hasBadge(customUser, 1);
      if (!repeat) {
        const bdayBadge = this.makeNewBadge(1, now.format());
        this.giveBadge(bdayBadge, customUser, false);
        anyBadges = true;
      }
    }
    if (anyBadges || customUser.badgeQueue.length > 0) {
      this.setState({
        badgeReportActive: !this.state.liveMode
      });
    }
  }
  isBirthday(customUser, now) {
    var birthday = moment(customUser.birthday);
    if (now.date() === birthday.date() && now.month() === birthday.month()) {
      return true;
    }
    else return false;
  }
  
  
  // Points
  givePoints(p, customUser, memo) {
    var points = customUser.points + p;
    customUser.points = points;
    // Toast for everything except new badges
    if (memo !== 'New Badge') {
      const heading = memo;
      const toastText = `+${p} pts!`;
      const pointsToast = memo === 'Check In' ? 
        this.makeNewToast(toastText, 'bg-gold toast-points', heading, points)
        : 
        this.makeNewToast(toastText, 'toast-points', heading, points);
      this.pushToast([pointsToast]);
    }
  }
  removePoints(p, customUser, memo) {
    var points = customUser.points - p;
    if (points < 0)
      points = 0;
    customUser.points = points;
    const heading = memo;
    const toastText = `-${p} pts!`;
    const pointsToast = this.makeNewToast(toastText, 'toast-points', heading, points);
    this.pushToast([pointsToast]);
  }
  deleteAllPoints(customUser) {
    customUser.points = 0;
    this.updateUserData(customUser);
  }

  // Accessibility offerings
  makeNewOffering(name) {
    const offering = {
      name: name
    };
    return offering;
  }

  // Sponsors
  // Read all sponsors
  readAllSponsorData() {
    const sponsorsRef = firebase.database().ref('sponsors');
    // Read sponsor data once
    sponsorsRef.once('value')
      .then((snapshot) => {
        // Grab the data snapshot
        const snapshotVal = this.snapshotToArray(snapshot);
        // Rebuild sponsors from snapshot
        const sponsors = snapshotVal.map((s) => this.rebuildSponsor(s));
        this.setState({
          sponsors
        });
      });
  }
  // Update Sponsor in front end
  updateSponsorInApp(sponsor) {
    let sponsors = this.state.sponsors;
    const index = this.state.sponsors.findIndex(s => s.id === sponsor.id);
    sponsors[index] = sponsor;
    this.setState({
      sponsors
    });
  }
  // Delete sponsor in front end
  deleteSponsor(id) {
    let sponsors = this.state.sponsors;
    const index = sponsors.findIndex(s => s.id === id);
    sponsors.splice(index, 1);
    this.setState({
      sponsors
    });
  }
  // Rebuild Sponsor object from partial database entry
  rebuildSponsor(sponsorData) {
    const sponsor = {
      id: sponsorData.id || null,
      name: sponsorData.name || null,
      level: sponsorData.level || null, 
      sponsorURL: sponsorData.sponsorURL || null, 
      imageURL: sponsorData.imageURL || [], 
      noLogo: sponsorData.noLogo || false, 
    };
    return sponsor;
  }

  // Programs
  // Update Program in front end
  updateProgramInApp(program) {
    let programs = this.state.programs;
    const index = programs.findIndex(p => p.id === program.id);
    programs[index] = program;
    this.setState({
      programs
    });
  }
  // Read full program array
  readAllProgramData() {
    const programsRef = firebase.database().ref('programs');
    // Read event data once
    programsRef.once('value')
      .then((snapshot) => {
        // Grab the data snapshot
        const snapshotVal = this.snapshotToArray(snapshot);
        // Rebuild Programs from snapshot
        const programs = snapshotVal.map((e) => this.rebuildProgram(e));
        this.setState({
          programs
        });
      });
  }
  rebuildProgram(programData) {
    const program = {
      id: programData.id || null,
      elements: programData.elements || [],
      path: programData.path || null,
    };
    return program;
  }

  resetPassword(email) {
    auth.sendPasswordResetEmail(email)
      .then(() => {
        console.log('email sent');
      })
      .catch((error) => {
        console.log(error.code, error.message);
      });
  }
  reauthenticateThenDelete(email, password) {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    const user = firebase.auth().currentUser;
    user.reauthenticateWithCredential(credential)
      .then(() => {
        this.deleteUser(user);
      });
  }
  deleteUser(user) {
    // Mark customUser as deleted in database
    const dRef = firebase.database().ref('users/' + user.uid);
    dRef.once('value')
      .then((snapshot) => {
        const snapshotVal = snapshot.val();
        const customUser = this.rebuildCustomUser(snapshotVal);
        customUser.deleted = moment().format();
        dRef.set(customUser);
        // Now delete the user from auth
        user.delete()
          .then(() => {
            this.pushToHistory('/deleteconfirm');
          })
          .catch((err) => {
            // Log error and reset user data to database
            customUser.deleted = null;
            dRef.set(customUser);
          })
      });
  }

  clearStorage(path) {
    // Delete from storage
    // get ref to uploaded files
    const sRef = firebase.storage().ref(path);
    // list all files
    sRef.listAll()
      .then((res) => {
        if (res.items) 
          // recursively delete all files
          res.items.forEach((file) => {
            const fRef = firebase.storage().ref(file.location.path_);
            fRef.delete()
              .catch((err) => {
                console.error(err.code, err.message);
              });
          });
      });
  }

  submitFeedback(feedback) {
    var customUser = this.state.customUser;
    customUser.feedback = feedback;
    this.givePoints(100, customUser, 'Submitted Feedback');
    this.updateUserData(customUser);
  }
  deleteFeedback(custom) {
    var customUser = custom;
    custom.feedback = null;
    this.updateUserData(customUser);
  }

  clickBookmark(event) {
    var customUser = this.state.customUser;
    const bookmarked = customUser.bookmarks.includes(event.id);
    if (bookmarked) 
      this.removeBookmark(customUser, event.id);
    else
      this.addBookmark(customUser, event.id);
    this.updateUserData(customUser);
  }
  addBookmark(customUser, id) {
    // grab CustomUser
    // Only add if not already bookmarked
    if (!customUser.bookmarks.includes(id)) {
      // grab entire schedule but discard what's not bookmarked
      // Filter events to only bookmarked events and new bookmark
      var bookmarkedEvents = this.state.schedule.filter(event =>  
        customUser.bookmarks.includes(event.id) || event.id === id
      );
      // Use map to get array of bookmarked event ids
      const bookmarks = bookmarkedEvents.map((event) => {
        return event.id;
      });
      // Set new bookmarks array to customUser
      customUser.bookmarks = bookmarks;
      // Check whether to award bookmarks badges
      this.handleBookmarkBadges(customUser);
      // give points
      this.givePoints(25, customUser, 'Add Bookmark');

      // Push notifications
      if (firebase.messaging.isSupported()) {
        if (this.state.messaging) {
          console.log('have messaging obj');
        }
        else {
          console.log('dont have messaging obj yet');
          this.initMessaging(customUser);
        }
      }
    }
  }
  removeBookmark(customUser, id) {
    if (customUser.bookmarks.includes(id)) {
      var bookmarks = [...customUser.bookmarks];
      const index = bookmarks.findIndex((b) => b.id === id);
      bookmarks.splice(index, 1);
      customUser.bookmarks = bookmarks;
      this.removePoints(25, customUser, 'Remove Bookmark');
    }
  }
  addToLibrary(customUser, eventID) {
    // push id to Library
    if (!customUser.library.includes(eventID)) {
      var library = [...customUser.library];
      library.push(eventID);
      customUser.library = library;
      //this.updateUserData(customUser);
      this.givePoints(50, customUser, 'Library Add')
    }
  }
  // Delete program from library
  deleteFromLibrary(customUser, id) {
    var library = [...customUser.library];
    const index = library.findIndex((p) => p.id === id);
    library.splice(index, 1);
    customUser.library = library;
    this.removePoints(50, customUser, 'Library Remove');
  }

  initMessaging(customUser) {
    getMessagingAndToken()
    .then((res) => {
      if (res !== null) {
        const messaging = res.messaging;
        const token = res.token;
        messaging.onMessage((payload) => {
          console.log('message received. payload:', payload);
        });
        if (token) {
          //console.log('got token:', token);
          this.sendTokenToServer(token, customUser);
          //this.updateUIForPushEnabled(token);
        }
        else {
          // Show permission request.
          //console.log('No Instance ID token available. Request permission to generate one.');
          // Show permission UI.
          //this.updateUIForPushPermissionRequired();
          this.setTokenSentToServer(false);
        }
        this.setState({ 
          messaging: messaging,
          currentToken: token
        });
      }
      else {
        // Show permission request.
        console.log('No Instance ID token available. Request permission to generate one.');
        // Show permission UI.
        //this.updateUIForPushPermissionRequired();
        this.setTokenSentToServer(false);
      }
    })
  }

  // Check in to event
  checkIn(eventID, customUser, startTime) {
    // Only add to attendance once
    if (!customUser.attendance.includes(eventID)) {
      // Grab customUser
      // Give points for Check In
      this.givePoints(100, customUser, 'Check In');
      // add to attendance array
      var attendance = [...customUser.attendance];
      attendance.push(eventID);
      this.handleAttendanceBadges(customUser);
      this.handleMatineeBadge(customUser, startTime);
      // Add program to library
      this.addToLibrary(customUser, eventID);
      // Add everything back to customUser
      customUser.attendance = attendance;
      // set customUser in database & front end
      this.updateUserData(customUser, 'from checkIn()');
    }
    /*else {
      console.log(`you can't check in a second time son`);
    }*/
  }

  handleAttendanceBadges(customUser, test = 0) {
    if (customUser.attendance.length >= 1 || test === 1) {
      if (!this.hasBadge(customUser, 3)) {
        const one = this.makeNewBadge(3, moment().format());
        this.giveBadge(one, customUser, false);
      }
    }
    if (customUser.attendance.length >= 3 || test === 3) {
      if (!this.hasBadge(customUser, 4)) {
        const three = this.makeNewBadge(4, moment().format());
        this.giveBadge(three, customUser, false);
      }
    }
    if (customUser.attendance.length >= 5 || test === 5) {
      if (!this.hasBadge(customUser, 5)) {
        const five = this.makeNewBadge(5, moment().format());
        this.giveBadge(five, customUser, false);
      }
    }
    /*else if (customUser.attendance.length === 10 || test === 10) {
      const ten = this.makeNewBadge(6, moment().format());
      this.giveBadge(ten, customUser, false);
    }*/
  }
  handleMatineeBadge(customUser, startTime) {
    var hours = moment(startTime).hours();
    if (hours < 17) {
      if (!this.hasBadge(customUser, 15)) {
        const matinee = this.makeNewBadge(15, moment().format());
        this.giveBadge(matinee, customUser, false);
      }
    }
  }
  handleBookmarkBadges(customUser) {
    if (customUser.bookmarks.length >= 1) {
      if (!this.hasBadge(customUser, 9)) {
        const bookmarks1 = this.makeNewBadge(9, moment().format());
        this.giveBadge(bookmarks1, customUser, !this.state.liveMode);
      }
    }
    if (customUser.bookmarks.length >= 5) {
      if (!this.hasBadge(customUser, 10)) {
        const bookmarks5 = this.makeNewBadge(10, moment().format());
        this.giveBadge(bookmarks5, customUser, !this.state.liveMode);
      }
    }
    if (customUser.bookmarks.length >= 10) {
      if (!this.hasBadge(customUser, 11)) {
        const bookmarks10 = this.makeNewBadge(11, moment().format());
        this.giveBadge(bookmarks10, customUser, !this.state.liveMode);
      }
    }
  }
  handleLiveModeBadges(customUser) {
    if (customUser.liveModeCount.length >= 1) {
      if (!this.hasBadge(customUser, 12)) {
        const liveMode1 = this.makeNewBadge(12, moment().format());
        this.giveBadge(liveMode1, customUser, false);
      }
    }
    if (customUser.liveModeCount.length >= 5) {
      if (!this.hasBadge(customUser, 13)) {
        const liveMode5 = this.makeNewBadge(13, moment().format());
        this.giveBadge(liveMode5, customUser, false);
      }
    }
    if (customUser.liveModeCount.length >= 10) {
      if (!this.hasBadge(customUser, 14)) {
        const liveMode10 = this.makeNewBadge(14, moment().format());
        this.giveBadge(liveMode10, customUser, false);
      }
    }
  }
  makeNewToast(body, className = '', heading = '', points = null) {
    const newToast = {
      heading, 
      body, 
      className, 
      points
    }
    return newToast;
  }
  pushToast(toasts) {
    console.log('push', toasts.length, 'toast(s)');
    var queue = [...this.state.toastQueue];

    toasts.forEach(t => {
      queue.push(t);
    });
    console.log('pushed.', queue);

    this.setState({
      toastQueue: queue,
    });

    if (!this.state.toastActive) {
      this.setToastActive(toasts[0]);
    }
  }
  setToastActive(toast) {
    // Toast active to next toast or null
    if (!toast) {
      var queue = [...this.state.toastQueue];
      queue.splice(0, 1);
      this.setState({
        toastQueue: queue, 
      });
      if (queue.length > 0)
        this.startNextToast(400);
    }
    this.setState({
      toastActive: toast,
    });
  }
  startNextToast(delay) {
    setTimeout(() => {
      this.setToastActive(this.state.toastQueue[0]);
    }, delay);
  }

  pushToHistory(path) {
    this.props.history.push(path);
  }
  snapshotToArray(snapshot) {
    var array = [];
    snapshot.forEach(function(obj) {
      const val = obj.val();
      //var object = val[Object.keys(val)[0]];
      var object = val;
      array.push(object);
    });
    return array;
  };
  // Send the Instance ID token your application server, so that it can:
  // - send messages back to this app
  // - subscribe/unsubscribe the token from topics
  sendTokenToServer(token, customUser) {
    //console.log(this.isTokenSentToServer() ? 'token was sent to server' : 'token not yet sent to server');
    if (!this.isTokenSentToServer()/* || this.isTokenSentToServer()*/) {
      var tokens = [...customUser.tokens];
      // Look for previous token in local storage
      const prevToken = window.localStorage.getItem('prevToken');
      if (prevToken) {
        // Remove previous token if it exists
        if (tokens.includes(prevToken)) {
          const index = tokens.findIndex(t => t === prevToken);
          tokens.splice(index, 1);
        }
      }
      tokens.push(token);
      customUser.tokens = tokens;
      // Set old token in local storage
      window.localStorage.setItem('prevToken', token);
      // Update customUser in front end & database
      //this.updateUserData(customUser);
      this.setTokenSentToServer(true);
    }
  }
  isTokenSentToServer() {
    return window.localStorage.getItem('sentToServer') === '1';
  }
  setTokenSentToServer(sent) {
    window.localStorage.setItem('sentToServer', sent ? '1' : '0');
  }

  handleFocus() {
    this.checkCurrentTime();
  }
  handleBlur() {
    this.clearTimeCheckTimeout();
  } 

  componentDidMount() {
    // Listen for changes to auth state
		auth.onAuthStateChanged((user) => {
			if (user) {
        // Grab CustomUser object from database
        this.getCustomUserData(user, 'from authStateChanged()');
        // Cache User object in state
        this.setState({
          user, 
        });
			}
			else {
        // User logged out
        this.setState({
          user: null,
          customUser: null,
        });
			}
    });
    // Listen for changes in focus
    window.addEventListener('focus', (e) => this.handleFocus());
    window.addEventListener('blur', (e) => this.handleBlur());
    // Read crucial data
    //if (this.state.schedule.length < 1)
      this.readAllEventData();
    //if (this.state.programs.length < 1)
      this.readAllProgramData();
    //if (this.state.tags.length < 1) 
      this.readAllTagData();
      //
    this.readAllSponsorData();
  }
  render() {
    return (
      <>
        <Toolbar 
          app={this} 
          logInSpinnerActive={this.state.logInSpinnerActive} 
          customUser={this.state.customUser} 
          liveMode={this.state.liveMode} />
        <main className={this.state.liveMode ? 'live' : ''}>
          {this.state.badgeReportActive &&
            <BadgeReport app={this} />
          }
          {this.state.fileBrowserActive && 
            <FileBrowser
              app={this} 
              firebase={firebase} 
              context={this.state.fileBrowserData.context} 
              path={this.state.fileBrowserData.path} 
              callback={this.state.fileBrowserData.callback} />
          }
          {!this.state.badgeReportActive && 
            <>
            <Route path="/upload">
              <FileUpload firebase={firebase} callback={this.uploadCallback}/>
            </Route>
            <Route exact path="/">
              <About customUser={this.state.customUser} />
            </Route>
            <Route path="/events">
              <ScheduleRead app={this} schedule={this.state.schedule} programs={this.state.programs} />
            </Route>
            <Route path="/signup">
              <SignUp firebase={firebase} app={this} />
            </Route>
            <Route path="/login">
              <LogIn app={this} />
            </Route>
            <Route path="/resetpassword">
              <ResetPassword app={this} />
            </Route>
            <Route path="/deleteconfirm" component={DeleteConfirm} />
            <Route path="/fineprint" component={FinePrint} />
            <Route path="/help">
              <Onboarding customUser={this.state.customUser} manual />
            </Route>
            <Route path="/leaderboard">
              <Leaderboard app={this} />
            </Route>
            {this.state.customUser &&
              <PatronApp app={this} customUser={this.state.customUser} />
            }
            </>
          }
          
        </main>
        <Footer 
          liveMode={this.state.liveMode} 
          fileBrowserActive={this.state.fileBrowserActive} />
        <Toaster 
          manager={this} 
          toast={this.state.toastActive} />
        <Modal manager={this} />
      </>
    );
  } 
}

export default withRouter(App);