import React from 'react';

function SponsorInfo(props) {
  var getLevelString = (level) => {
    if (level === 'platinum')
      return 'Platinum Partner';
	else if (level === 'gold')
	  return 'Gold Partner';
    else if (level === 'silver')
      return 'Silver Partner';
    else if (level === 'foundationgrant') 
      return 'Foundation & Grant Support';
    else if (level === 'donorextralarge') 
      return 'Luminary Donor'
    else if (level === 'donorlarge') 
      return '$1,000 - $4,999';
    else if (level === 'donormedium')
      return '$500 - $999';
    else return '$100 - $499';
  }
  var clickEdit = (e) => {
    props.manager.setState({
      sponsorWriteActive: true,
      sponsorToWrite: props.sponsor
    });
  }
  return (
    <div className="sponsor-info">
      <button className="cta cta-small cta-tool cta-trans" onClick={clickEdit}>Edit</button>
      <h3>{props.sponsor.name}</h3>
      <p>{getLevelString(props.sponsor.level)}</p>
      {props.sponsor.sponsorURL && props.sponsor.sponsorURL.length > 0 &&
        <p><strong>URL </strong>{props.sponsor.sponsorURL}</p>
      }
      {props.sponsor.noLogo ? 
        <p>No Logo</p>
        :
        <div>
          <img 
            height="150px" 
            src={props.sponsor.imageURL} 
            alt={'Thumbnail of ' + props.sponsor.imageURL} />
        </div>
      }
    </div>
  );
}

export default SponsorInfo;