import React from 'react';
import Breadcrumbs from './breadcrumbs';
import ConfirmButtons from './confirmButtons';
//import FileBrowser from './fileBrowser';

class ElementWrite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eType: 'headinglarge',
      innerHTML: '',
      url: '',
      alt: '',
      caption: '', 
      confirmButtonsActive: 0,
      //fileBrowserActive: false,
    };
    this.closeElementWrite = this.closeElementWrite.bind(this);
    this.setURL = this.setURL.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }
  closeElementWrite() {
    window.scrollTo(0, 0);
    this.props.manager.setState({
      elementWriteActive: false,
      elementToWrite: {},
    });
    this.setState({
      confirmButtonsActive: 0,
    });
  }
  confirmDelete() {
    // grab id
    const id = this.props.elementToWrite.id;
    // close event write
    this.closeElementWrite();
    this.props.manager.deleteElement(id);
  }
  setURL(e, url) {
    this.setState({
      url: url 
    });
    this.props.app.setState({
      fileBrowserActive: false 
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    // reassemble element data from state
    const element = this.props.manager.makeNewElement(
      this.props.elementToWrite.id, 
      this.state.eType, 
      this.state.innerHTML, 
      this.state.url, 
      this.state.alt,
      this.state.caption, 
    );
    // set element in database & front end
    this.props.manager.updateElement(element);
    // Close event write
    this.closeElementWrite();
  }
  loadElementData() {
    this.setState({
      eType: this.props.elementToWrite.eType || 'headinglarge',
      innerHTML: this.props.elementToWrite.innerHTML || '',
      url: this.props.elementToWrite.url || '',
      alt: this.props.elementToWrite.alt || '',
      caption: this.props.elementToWrite.caption || '', 
    });
  }
  componentDidMount() {
    this.props.app.setState({
      fileBrowserData: {
        context: 'events/' + this.props.manager.state.programToWrite.id,
        path: 'events/' + this.props.manager.state.programToWrite.id,
        callback: this.setURL
      }
    });
    this.loadElementData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.elementToWrite !== this.props.elementToWrite && 
      this.props.elementToWrite) {
      this.loadElementData();
    }
  }
  render() {
    return (
      <div id="element-write" className={this.props.manager.state.elementWriteActive ? 'hiding active' : 'hiding'}>
        <div className="standard-page">
          <Breadcrumbs onClick={this.closeElementWrite} innerHTML={'Edit Program'} />
          <h2>Edit Element</h2>
          <p>Here you can edit the appearance and content of this element. Nothing will be saved until you click Save below.</p>
          <form onSubmit={(e)=>this.handleSubmit(e)}>
            <label htmlFor="eType">Type
              <select 
                required 
                id="eType"
                name="eType" 
                value={this.state.eType} 
                onChange={(e) => this.handleChange(e)}>
                <option value="headinglarge">Heading Large</option>
                <option value="headingsmall">Heading Small</option>
                <option value="headingvertical">Heading Vertical</option>
                <option value="body">Body Paragraph</option>
                <option value="herotext">Hero Text</option>
                <option value="imagepadded">Padded Image</option>
                <option value="imagefullwidth">Full Width Image</option>
                <option value="linkbutton">Link Button</option>
              </select>
            </label>             
            {this.state.eType !== 'imagepadded' && this.state.eType !== 'imagefullwidth' && 
              <label>
                Display Text
                {this.state.eType === 'body' ? 
                  <textarea
                    required
                    id="innerHTML" 
                    name="innerHTML"
                    type="text" 
                    placeholder="Lorem ipsum sit dolor met." 
                    value={this.state.innerHTML} 
                    onChange={(e) => this.handleChange(e)} />
                  :
                  <input 
                    required 
                    id="innerHTML" 
                    name="innerHTML" 
                    type="text" 
                    placeholder="Lorem ipsum sit dolor met." 
                    value={this.state.innerHTML} 
                    onChange={(e)=>this.handleChange(e)} />
                }
              </label>
            }
            {this.state.eType !== 'headinglarge' && this.state.eType !== 'headingsmall' &&  
              this.state.eType !== 'headingvertical' && this.state.eType !== 'body' && 
              this.state.eType !== 'herotext' && 
              <label>
                URL
                <input 
                  required 
                  id="url" 
                  name="url" 
                  type="text" 
                  placeholder="https://urllocation.com/image.jpg" 
                  value={this.state.url} 
                  onChange={(e)=>this.handleChange(e)} />
                {this.state.eType !== 'linkbutton' && 
                  <button id="profAddPic"
                  className="cta cta-trans button-icon prof-pic-add" 
                    type="button" 
                    onClick={(e) => this.props.app.setState({fileBrowserActive: true})}>
                    Open File Browser
                  </button>
                }
              </label>
            }
            {this.state.eType !== 'headinglarge' && this.state.eType !== 'headingsmall'  
              && this.state.eType !== 'headingvertical' && this.state.eType !== 'body' 
              && this.state.eType !== 'herotext' && this.state.eType !== 'linkbutton' &&
              <>
                <label>
                  Image Description
                  <input 
                    required 
                    id="alt" 
                    name="alt" 
                    type="text" 
                    placeholder="a sunrise through the trees" 
                    value={this.state.alt} 
                    onChange={(e)=>this.handleChange(e)} />
                </label>
                <label>
                  Caption
                  <input 
                    required 
                    id="caption" 
                    name="caption" 
                    type="text" 
                    placeholder="The view from our campsite" 
                    value={this.state.caption} 
                    onChange={(e)=>this.handleChange(e)} />
                </label>
              </>
            }
            <input 
              type="submit" 
              value="Save" />
            <span className="confirmButtons-neg">
            <ConfirmButtons
              manager={this} 
              active={this.state.confirmButtonsActive} 
              first={'Delete Element'} 
              second={'Confirm Deletion'} 
              callback={this.confirmDelete} />
              </span>
          </form>
        </div>
      </div>
    );
  }
}

export default ElementWrite;