import React from 'react';
import { withRouter } from 'react-router-dom';

function ElementRead(props) {
  var formatEType = (eType) => {
    var str;
    switch (eType) {
      case 'headinglarge':
        str = 'Heading Large';
        break;
      case 'headingsmall':
        str = 'Heading Small'
        break;
      case 'headingvertical':
        str = 'Heading Vertical';
        break;      
      case 'body':
        str = 'Body Paragraph'
        break;
      case 'herotext':
        str = 'Hero Text';
        break;
      case 'imagepadded':
        str = 'Image Padded';
        break;
      case 'imagefullwidth':
        str = 'Image Full Width';
        break;
      case 'linkbutton':
        str = 'Link Button';
        break;
      default: str = '';
    }
    return str;
  }
  var openURL = (e, url) => {
    window.open(url, '_blank');
  }
  var clickEdit = (e) => {
    window.scrollTo(0, 0);
    props.manager.setState({
      elementToWrite: props.element,
      elementWriteActive: true,
    });
  }
  var clickMoveUp = (e) => {
    props.manager.moveElement('up', props.element);
  }
  var clickMoveDown = (e) => {
    props.manager.moveElement('down', props.element);
  }
  return (
    <>
      {props.write &&
        <div>
          <button className="cta cta-small cta-tool cta-black" onClick={(e) => clickEdit(e)}>Edit</button>
          <span>{formatEType(props.element.eType)}</span>
          <button className="cta cta-small cta-tool cta-trans" onClick={(e) => clickMoveUp(e)}>Move up</button>
          <button className="cta cta-small cta-tool cta-trans" onClick={(e) => clickMoveDown(e)}>Move down</button>
        </div>
      }
      {props.element.eType === 'headinglarge' &&
        <h2 className="heading-large">{props.element.innerHTML}</h2>
      }
      {props.element.eType === 'headingsmall' && 
        <h3 className="heading-small">{props.element.innerHTML}</h3>
      }
      {props.element.eType === 'headingvertical' && 
        <h2 className="fixed-header">{props.element.innerHTML}</h2>
      }
      {props.element.eType === 'body' && 
        <p className="body-paragraph">{props.element.innerHTML}</p>
      }
      {props.element.eType === 'herotext' && 
        <p className="hero-text">{props.element.innerHTML}</p>
      }
      {props.element.eType === 'imagepadded' && !props.liveMode && 
        <>
          <picture className="image-padded">
            <img src={props.element.url} alt={props.element.alt} />
          </picture>
          {props.element.caption && 
            <p className="image-caption">{props.element.caption}</p>
          }
        </>
      }
      {props.element.eType === 'imagefullwidth' && !props.liveMode && 
        <>
          <picture className="image-full-width">
            <img src={props.element.url} alt={props.element.alt} />
          </picture>
          {props.element.caption && 
            <p className="image-caption">{props.element.caption}</p>
          }
        </>
      }
      {props.element.eType === 'linkbutton' && !props.liveMode && 
        <button 
          className="cta" 
          onClick={e => openURL(e, props.element.url)}>
          {props.element.innerHTML}
        </button>
      }
    </>
  );
}

export default withRouter(ElementRead);