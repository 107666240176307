import React from 'react';
import { withRouter } from 'react-router-dom';
import ScheduleIcon from '../img/icons/bookmark-bookmarked.png';
import BadgesIcon from '../img/badges/badge-check_in-3-black.png';
import LeaderboardIcon from '../img/icons/leaderboard.png';
import HelpIcon from '../img/icons/help.png';
import TicketImage from '../img/icons/ticket.png';
import CheckinImage from '../img/icons/checkin.png';
import LiveImage from '../img/icons/livemode.png';
import LibraryImage from '../img/icons/library-add.png';

class Onboarding extends React.Component {
  render() {
    return (
      <div id="onboarding" className="standard-page">
        <picture>
          <img width="64" src={HelpIcon} alt="Help icon"/>
        </picture>
        {!this.props.manual ? 
          <>
            <p>First time here?</p>
            <h1>Welcome!</h1>
          </>
          :
          <>
            <h1>How it Works</h1>
          </>
        }
        <span className="text-left">
          <p className="text-large"><span className="term">Arts MU Live!</span> provides our patrons with paperless <span className="term">Programs</span>. This new way of delivering <span className="term">Programs</span> has a lot of advantages over the traditional print method.</p>
          <p>We can offer our patrons more interactive ways to learn about their favorite performances, add or update program content without re-printing all those programs, and it's a big step forward in <span className="term">The Arts at MU</span>'s ongoing mission of increased sustainability.</p>
        </span>
        <h2>How to Use Arts MU Live!</h2>
        <ol>
          <li>
            <span>
              <picture>
                <img width="64" src={TicketImage} alt="Get Your Ticket"/>
              </picture>
              <h3>Get Dolled Up</h3>
            </span>
            <p>Buy your tickets, get dressed up, maybe stop for some dinner, and make your way to the theatre just like always.</p>
          </li>
          <li>
            <span>
              <picture>
                <img width="64" src={CheckinImage} alt="Schedule View > Program > Check In"/>
              </picture>
              <h3>Check In at the Show</h3>
            </span>
            <p>Open <span className="term">Arts MU Live!</span> in the lobby and find your show in the <span className="term">Schedule</span>. Click the <span className="term">Read Program</span> button in your show's info and we'll confirm your location so you can <span className="term">Check In</span>!</p>
          </li>
          <li>
            <span>
              <picture>
                <img width="64" src={LiveImage} alt="Live Mode Icon"/>
              </picture>
              <h3>Relax and Enjoy</h3>
            </span>
            <p>We'll deliver the <span className="term">Program</span> for your show right to your device and automatically change to a darkened <span className="term">Live Mode</span>  so you can check your <span className="term">Program</span> without lighting up the auditorium.</p>
          </li>
          <li>
            <span>
              <picture>
                <img width="64" src={LibraryImage} alt="Library Icon"/>
              </picture>
              <h3>Grow Your Library</h3>
            </span>
            {this.props.customUser ? 
              <p>Your new program will be added to your <span className="term">Library</span> upon <span className="term">Check In</span> so you can easily find it again. If you'd like a paper keepsake from the show, our <span className="term">Programs</span> are automatically print-ready!</p>
              :
              <p>Register with us to create a free <span className="term">Profile</span> and start a collection of <span className="term">Programs</span> in your <span className="term">Library</span>. <span className="term">Programs</span> are automatically print-ready if you'd like a paper keepsake from the show!</p>
            }
          </li>
        </ol>
        {!this.props.customUser && 
          <button 
            className="cta"
            onClick={(e) => this.props.history.push('/signup')}>
            Sign Up
          </button>
        }
        <h2>Even More Fun</h2>
        <p>With your <span className="term">Profile</span> you can also climb the <span className="term">Leaderboard</span>, collect <span className="term">Badges</span>, and get reminders about upcoming shows!</p>
         <ul className="flex-3">
           <li>
             <span>
               <img className="thumbnail" width="64" src={LeaderboardIcon} alt="Leaderboard icon"/>
               <h3>Leaderboard</h3>
             </span>
             <p>When you use <span className="term">Arts MU Live!</span> you'll earn <span className="term">Points</span>. Check the <span className="term">Leaderboard</span> to see how your <span className="term">Score</span> stacks up against your fellow patrons.</p>
           </li>
           <li>
             <span>
               <img className="thumbnail" id="badgesIcon" width="64" src={BadgesIcon} alt="Badges icon"/>
               <h3>Badges</h3>
             </span>
             <p>We'll award you <span className="term">Badges</span> for your achievements, like attending shows, trying out features, and reaching the top of the <span className="term">Leaderboard</span>.</p>
           </li>
           <li>
             <span>
               <img className="thumbnail" width="64" src={ScheduleIcon} alt="Bookmarks icon"/>
               <h3>Reminders</h3>
             </span>
             <p>Use <span className="term">Bookmarks</span> to opt-in for reminders about upcoming shows. Get alerts for the return of a beloved artist or discover a new favorite!</p>
           </li>
         </ul>
      </div>
    );
  }
}

export default withRouter(Onboarding);