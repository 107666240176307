import React from 'react';
import { withRouter } from 'react-router-dom';

function DeleteConfirm(props) {
  var pushToHistory = (e, path) => {
    props.history.push(path);
  }
  return (
    <div className="standard-page">
      <h1>Success!</h1>
      <p>Your account was deleted.</p>
      <button className="cta" onClick={(e) => pushToHistory(e, '/')}>OK</button>
    </div>
  );
}

export default withRouter(DeleteConfirm);