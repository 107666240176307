import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import LibraryList from './libraryList';
import BookmarksList from './bookmarksList';
import TierDisplay from './tierDisplay';
import BadgesList from './badgesList';
import Feedback from './feedback';
import ScheduleIcon from '../img/icons/bookmark-bookmarked.png';
import LeaderboardIcon from '../img/icons/leaderboard.png';

class UserRead extends React.Component {
  logOut(e) {
    this.props.app.logOut();
    this.pushToHistory({}, '/login');
  }
  pushToHistory(e, path) {
    const history = this.props.history;
    history.push(path);
  }
  getTestPoints() {
    var customUser = this.props.app.state.customUser;
    this.props.app.givePoints(100, customUser, 'Testing points');
    this.props.app.updateUserData(customUser);
  }
  getTestBadge() {
    var customUser = this.props.app.state.customUser;
    var m = moment().format();
    const testBadge = this.props.app.makeNewBadge(0, m);
    this.props.app.giveBadge(testBadge, customUser);
    this.props.app.updateUserData(customUser);
  }
  clickTooltip(id) {
    const tips = [
      { 
        heading: 'Score', 
        paragraph: 'Raise your Score by earning Points! Using features like Bookmarks and Checking In at shows will earn you Points.', 
        cta: 'OK' 
      },
      { 
        heading: 'Tier', 
        paragraph: 'Accumulate Points to rise from Bronze Tier to Silver Tier, and then all the way up to the prestigous Gold Tier!', 
        cta: 'OK' 
      },
    ];
    this.props.app.setState({
      modalActive: true,
      modalData: {
        heading: tips[id].heading,
        paragraph: tips[id].paragraph,
        cta: tips[id].cta
      }
    });
  }
  componentDidMount() {
    if (this.props.app.state.schedule.length < 1)
      this.props.app.readAllEventData();
  }
  componentDidUpdate() {
    if (this.props.customUser && this.props.customUser.badgeQueue.length > 0) {
      this.props.app.setState({
        badgeReportActive: !this.props.app.state.liveMode
      });
    }
  }
  render() {
    return (
      <div id="profile">
        <ul id="profileTopLinks">
          <li>
            <button 
              id="profileSettings"
              className="cta cta-lg cta-light button-icon" 
              onClick={(e) => this.pushToHistory(e, '/profile/settings')}>
              <span className="text-hidden">Settings</span>
            </button>
          </li>
          <li>
            <button
              id="profileHelp"
              className="cta cta-light button-icon" 
              onClick={(e) => this.pushToHistory(e, '/help')}>
              <span className="text-hidden">Help</span>
            </button>
          </li>
          <li>
            <button
              id="profileLogOut"
              className="cta cta-light cta-small" 
              onClick={(e) => this.logOut(e)}>
              Log Out
            </button>
          </li>
        </ul>
        <div id="userInfo">
          {this.props.customUser.photoURL ? 
            <picture 
              className="picture-round profile-pic large" 
              >
              <img 
                height="100" 
                width="100" 
                alt={this.props.customUser.displayName} 
                src={this.props.customUser.photoURL} />
            </picture>
            :
            <button 
              id="profAddPic"
              className="cta cta-small button-icon cta-tool cta-trans" 
              onClick={(e) => this.pushToHistory(e, '/profile/settings')}>
              Set Profile Picture <br/>in Settings
            </button>            
          }
          <h5 className="text-center">Username</h5>
          <h1>{this.props.customUser.displayName}</h1>
          <ul>
            <li id="profScore">
              <h5>Score</h5>
              <span>
                <p>{this.props.customUser.points}</p>
                <button 
                  className="tooltip"
                  onClick={(e) => this.clickTooltip(0)}>
                  info
                </button>
              </span>
            </li>
            <li id="userTier">
              <h5>Tier</h5>
              <span>
              <TierDisplay tier={this.props.customUser.tier} />
              <button 
                className="tooltip"
                onClick={(e) => this.clickTooltip(1)}>
                info
              </button>
              </span>
            </li>
          </ul>
        </div>
        {this.props.customUser.admin &&
          <div
            className="admin-panel">
            <h2>Admin Dashboard</h2>
            <ul>
              <li>
                <button onClick={(e) => this.pushToHistory(e, 'dashboard/events')}>
                  Edit Events
                </button>
              </li>
              <li>
                <button onClick={(e) => this.pushToHistory(e, 'dashboard/sponsors')}>
                  Edit Partners
                </button>
              </li>
              <li>
                <button onClick={(e) => this.pushToHistory(e, 'dashboard/tags')}>
                  Edit Tags
                </button>
              </li>
              <li>
                <button onClick={(e) => this.pushToHistory(e, 'dashboard/users')}>
                  See All Users
                </button>
              </li>
            </ul>
          </div>
        }
        <div id="leaderboardLink" className="text-center promo">
          <img width="64" src={LeaderboardIcon} alt="Leaderboard icon"/>
          <h1>Leaderboard</h1>
          <p>See how you stack up. Track your <span className="term">Points</span> and rise to the top!</p>
          {/*<ul className="leaderboard">
            <li>
              <picture className="picture-round profile-pic">
                <img height="32" width="32" alt={this.props.customUser.displayName} src={this.props.customUser.photoURL} />
              </picture>
              <p>{this.props.customUser.displayName}</p>
              <p>{this.props.customUser.points}</p>
            </li>
          </ul>*/}
          <button 
            className="cta cta-light" 
            onClick={(e) => this.pushToHistory(e, '/leaderboard')}>
            View Leaderboard
          </button>
        </div>
        <div id="badgeCollection">
          <h2 className="text-center">My Badges</h2>
          {this.props.customUser.badges.length > 0 ? 
            <>
              <p className="text-center">Collect <span className="term">Badges</span> by attending events and exploring <span className="term">Arts MU Live!</span> features.</p>
              <BadgesList 
                badges={this.props.customUser.badges} 
                size={1} />
            </>
            :
            <div className="text-center">
                <p>You haven't earned any <span className="term">Badges</span> yet. Collect <span className="term">Badges</span> by attending events and exploring <span className="term">Arts MU Live!</span> features.</p>
            </div>
          }
        </div>
        <div id="library" className="promo">
          <h1>Library</h1>
          <>
            {this.props.app.state.customUser.library.length < 1 ?
              <div className="text-center">
                <h2>There&#39;s nothing here.</h2>
                <p>Your <span className="term">Library</span> is empty!<br /> 
                When you <span className="term">Check In</span> at a show, you can add that show&#39;s <span className="term">Program</span> to your <span className="term">Library</span>. How many <span className="term">Programs</span> can you collect?</p>
              </div>
              :
              <>
                <LibraryList 
                  app={this.props.app} 
                  library={this.props.app.state.customUser.library} />
              </>
            }
          </>
        </div>
        {this.props.customUser.bookmarks && 
          this.props.app.state.schedule && this.props.app.state.schedule.length > 0 && 
          <div id="bookmarks" className="section-skewed text-center">
             <img width="64" src={ScheduleIcon} alt="Schedule icon"/>
            <h1>Bookmarks</h1>
            <BookmarksList app={this.props.app} bookmarks={this.props.customUser.bookmarks} />
          </div>
        }
        {this.props.customUser.feedback === null && 
          <div id="survey">
            <h3>Help Us Grow</h3>
            <p>You can help improve <span className="term">Arts MU Live!</span> for future seasons by completing the feedback questionnaire below. It only takes a few seconds and we'll trade you 100 <span className="term">Points</span> for your answers!</p>
            <Feedback app={this.props.app} />
          </div>
        }
        {/*this.props.customUser.admin && 
          <div>
            <p>Testing area</p>
            <button className="cta cta-black cta-small" onClick={(e)=>this.getTestPoints()}>Get Test Points</button>
            <button className="cta cta-black cta-small" onClick={(e)=>this.props.app.deleteAllPoints(this.props.app.state.customUser)}>Clear Points</button>
            <button className="cta cta-black cta-small" onClick={(e)=>this.getTestBadge()}>Get Test Badge</button>
            <button className="cta cta-black cta-small" onClick={e=>this.props.app.deleteAllBadges(this.props.app.state.customUser)}>Clear Badges</button>
            <button className="cta cta-black cta-small" onClick={e=>this.props.app.deleteFeedback(this.props.app.state.customUser)}>Clear Feedback</button>
          </div>
        */}
      </div>
    );
  }
}

export default withRouter(UserRead);