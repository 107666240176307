import React from 'react';

class SignUpDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: '',
      fullName: '',
    }
  }
  clickSkip() {    
    this.props.signUp.pushToHistory({}, '/profile');
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    // copy customUser
    let customUser = this.props.app.state.customUser;
    // make data changes from form
    if (this.state.displayName.length > 0)
      customUser.displayName = this.state.displayName;
    if (this.state.fullName.length > 0)
      customUser.fullName = this.state.fullName;
    // set customUser
    this.props.app.updateUserData(customUser);
    // grab firebase user
    let user = this.props.app.state.user;
    // Prepare display name data
    const dn = this.state.displayName.length > 0 ? this.state.displayName : null;
    // update firebase user profile
    user.updateProfile({
      displayName: dn,
    })
    .catch((err) => {
      console.log(err);
    });
    this.props.signUp.setState({
      step: 2,
    });
  }
  render() {
    return (
      <div className="standard-page">
        <h1>Nice to meet you!</h1>
        <p>Please tell us a little more about yourself. You can always update this information in the <span className="term">Settings</span> page of your <span className="term">Profile</span>.</p>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <label>
            Full Name
            <input 
              id="full-name" 
              name="fullName" 
              type="text" 
              placeholder="John J. Appleseed, Jr." 
              required 
              value={this.state.fullName} 
              onChange={(e) => this.handleChange(e)} />
          </label>
          <label>
            Username
            <input 
              id="display-name" 
              name="displayName" 
              type="text" 
              placeholder="Johnny" 
              required 
              value={this.state.displayName} 
              onChange={(e) => this.handleChange(e)} />
          </label>
          {this.props.app.state.customUser &&
            <input 
              id="submit" 
              name="submit" 
              type="submit" 
              value="Save" />
          }
        </form>
      </div>
    );
  }
}
export default SignUpDetails;