import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

function EventFeature(props) {
  var getDayNight = (d) => {
    const m = moment(d);
    const val = m.hours() > 16 ? 'Tonight' : 'Today';
    return val;
  }
  var getTime = (d) => {
    return moment(d).format('h:mm A');
  }
  return (
    <div 
      className="event-feature"
      onClick={(e) => props.history.push('/events/' + props.event.path)}>
      {props.app.state.liveMode ? 
        <h1>Live Now!</h1>
        :
        <h1>{getDayNight(props.event.startTime)}&#39;s Performance</h1>
      }
      <h2 className="text-center">{props.event.title}</h2>
      <h3 className="text-center">{props.event.locationMain}</h3>
      <ul className="eventTags">
        <li>
          <p>{props.event.genre}</p>
        </li>
      </ul>
      <picture>
        <img src={props.event.coverPhotoURL} alt="featured event" />
      </picture>
      <h3 id="featuredTime" className="text-center">
        <span>{getDayNight(props.event.startTime)}</span><br/> 
        {getTime(props.event.startTime)}
      </h3>
    </div>
  );
}

export default withRouter(EventFeature);