import React from 'react';
import { withRouter } from 'react-router-dom';
import Breadcrumbs from './breadcrumbs';

class UserWrite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      displayName: '',
      photoURL: '',
    }
    this.setPhotoURL = this.setPhotoURL.bind(this);
  }

  pushToHistory(e, path) {
    const history = this.props.history;
    history.push(path);
  }

  setPhotoURL(e, url) {
    this.setState({
      photoURL: url 
    });
    this.props.app.setState({
      fileBrowserActive: false
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    var profPic = false;
    // copy customUser
    let customUser = this.props.app.state.customUser;
    // make data changes from form
    if (this.state.displayName.length > 0)
      customUser.displayName = this.state.displayName;
    if (this.state.fullName.length > 0)
      customUser.fullName = this.state.fullName;
    if (this.state.photoURL.length > 0) {
      // Give points for setting profile pic one time
      if (!customUser.setProfPic) {
        this.props.app.givePoints(50, customUser, 'Set Profile Pic');
        customUser.setProfPic = true;
        profPic = true;
      }
      customUser.photoURL = this.state.photoURL;
    }
    if (this.state.photoURL === 'unset')
      customUser.photoURL = 'https://firebasestorage.googleapis.com/v0/b/ware-421.appspot.com/o/profilePics%2Fdefault.png?alt=media&token=49bf0b13-2e44-4324-b298-ae2ec69fd6c4';

    // set customUser in database
    this.props.app.updateUserData(customUser);

    // grab firebase user
    let user = this.props.app.state.user;
    // Prepare display name data
    const dn = this.state.displayName.length > 0 ? this.state.displayName : null;
    // update firebase user profile
    user.updateProfile({
      displayName: dn,
    }).catch((err) => {
      console.log(err);
    });
    // Toast
    if (!profPic) {
      const toast = this.props.app.makeNewToast('Profile settings saved.');
      this.props.app.pushToast([toast]);
    }
    // Navigate back to profile
    this.pushToHistory({}, '/profile');
  }

  componentDidMount() {
    this.props.app.setState({
      fileBrowserData: {
        context: 'userWrite',
        path: 'profilePics/' + this.props.app.state.user.uid, 
        callback: this.setPhotoURL
      }
    });
    this.setState({
      fullName: this.props.app.state.customUser.fullName || '',
      displayName: this.props.app.state.customUser.displayName || '',
      photoURL: this.props.app.state.customUser.photoURL || '',
    });
  }
  
  render() {
    return (
      <div className={this.props.app.state.fileBrowserActive ? 
        'standard-page hiding' : 'standard-page hiding active'}>
        <Breadcrumbs 
          path={'/profile'} 
          innerHTML={'Profile'} />
        <h1>Settings</h1>
        <p>Edit your account settings here. Nothing will be saved until you click the Save button below.</p>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <label>
            Email
            <input 
              type="text" 
              value={this.props.app.state.customUser.email} 
              disabled />
          </label>
          <label>
            Full Name
            <input 
              id="full-name" 
              name="fullName" 
              type="text" 
              placeholder="John J. Appleseed, Jr." 
              value={this.state.fullName} 
              onChange={(e) => this.handleChange(e)} />
          </label>
          <label>
            Username
            <input 
              id="display-name" 
              name="displayName" 
              type="text" 
              placeholder="Johnny" 
              value={this.state.displayName} 
              onChange={(e) => this.handleChange(e)} />
          </label>
          <label id="profPictureAdd">
            Profile Picture
            {this.state.photoURL ? 
              <div>
                <picture className="picture-round">
                  <img alt={this.state.fullName} src={this.state.photoURL} />
                </picture>
                <button 
                  className="cta cta-neg cta-tool cta-small" 
                  type="button"
                  onClick={(e) => this.setPhotoURL(e, 'unset')}>
                  Remove
                </button>                    
                <button 
                  className="cta cta-trans button-icon prof-pic-add" 
                  type="button" 
                  onClick={(e) => this.props.app.setState({fileBrowserActive: true})}>
                  Change Picture
                </button>
              </div>               
              :
              <div>
                <button 
                  className="cta button-icon prof-pic-add" 
                  type="button" 
                  onClick={(e) => this.props.app.setState({fileBrowserActive: true})}>
                  Choose Picture
                </button>
              </div>
            }
          </label>
          <input 
            id="submit" 
            name="submit" 
            type="submit" 
            value="Save" />
        </form>
        <button className="cta cta-trans" onClick={(e) => this.pushToHistory(e, '/resetpassword')}>Reset Password</button>
        <button className="cta cta-neg" onClick={(e) => this.pushToHistory(e, '/profile/settings/delete')}>Delete Account</button>
      </div>
    );
  }
}

export default withRouter(UserWrite);