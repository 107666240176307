import React from 'react';
import { withRouter } from 'react-router-dom';
import Breadcrumbs from './breadcrumbs';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }
  pushToHistory(e, path) {
    const history = this.props.history;
    history.push(path);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit(e) {
    this.props.app.resetPassword(this.state.email);
    this.pushToHistory({}, '/profile');
  }
  render() {
    return (<>
      <Breadcrumbs path={"/profile"} innerHTML={"Profile"} />
      <div className="standard-page">
        <h1>Reset your password</h1>
        <p>You can reset your password via email. Enter your email address below and we&#39;ll send you a password recovery email.</p>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <label>
            Email
            <input 
              required 
              id="email" 
              name="email" 
              type="text" 
              placeholder="your@email.address" 
              value={this.state.email} 
              onChange={(e) => this.handleChange(e)} />
          </label>
          <input 
            className="cta" 
            type="submit" 
            value="Send Email" />
        </form>
        <button className="cta cta-trans" onClick={(e) => this.pushToHistory(e, '/')}>Cancel</button>
      </div>
    </>);
  }
}

export default withRouter(ResetPassword);