import React from 'react';
//import firebase from 'firebase/app';
//import 'firebase/database';
import firebase from '../firebase';
import Breadcrumbs from './breadcrumbs';
import TagList from './tagList';

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTag: '',
      confirmButtonsActive: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  deleteTag(name) {
    var tags = [...this.props.app.state.tags];
    const index = tags.findIndex(t => t.name === name);
    tags.splice(index, 1);
    const tRef = firebase.database().ref('/tags');
    this.props.app.removeTagFromSchedule(name);
    this.props.app.updateTagData(tags, tRef);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    const tRef = firebase.database().ref('/tags');
    this.props.app.postNewTag(this.state.newTag, tRef);
    const toast = this.props.app.makeNewToast('Added new Tag.');
    this.props.app.pushToast([toast]);
    this.setState({
      newTag: '',
    });
  }
  componentDidMount() {
    if(this.props.app.state.tags.length < 1) {
      this.props.app.readAllTagData();
    }
  }
  render() {
    return (
      <div id="tags" className="standard-page">       
        <Breadcrumbs 
          path={'/profile'} 
          innerHTML="Profile" />
        <h1>Tags</h1>
        <p><span className="term">Tags</span> can be attached to <span className="term">Events</span>. An <span className="term">Event&#39;s Tags</span> will determine which other <span className="term">Events</span> are similar. Create your <span className="term">Tags</span> here and then assign them while editing <span className="term">Events.</span></p>
        {this.props.app.state.tags.length > 0 && 
          <TagList manager={this} tags={this.props.app.state.tags} write />
        }
        <form onSubmit={this.handleSubmit}>
          <h2 className="text-center">Create a new Tag</h2>
          <label htmlFor="newTag">
            Tag name
            <input 
              id="newTag"
              type="text" 
              name="newTag" 
              min="1" 
              placeholder="Fall2020"
              required 
              value={this.state.newTag} 
              onChange={this.handleChange} />
          </label>
          <input 
            type="submit" 
            value="Add Tag" />
        </form>
      </div>
    )
  }
}

export default Tags;