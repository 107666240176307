import React from 'react';
import ElementRead from './elementRead';

function ElementsList(props) {
  var elementsList = props.elements.map((el, key) => {
    return ( 
      <li key={key}>
        <ElementRead 
          manager={props.manager} 
          element={el} 
          write={props.write} />
      </li> 
    );
  });
  return (
    <ul>
      {elementsList}
    </ul>
  );
}

export default ElementsList;