import React from "react";
import { withRouter } from "react-router-dom";
import MillersvilleLogo from "../img/millersvillelogo.png";
import ArtsMULogoLg from "../img/ArtsAtMU_swoosh_LG.png";
import ArtsMULogoSm from "../img/ArtsAtMU_swoosh_SM.png";
import LibraryImg from "../img/library.svg";
import CalendarImg from "../img/calendar.svg";
import GamificationImg from "../img/gamification.svg";
import ScheduleIcon from "../img/icons/schedule.png";

class About extends React.Component {
  pushToHistory(e, path) {
    const history = this.props.history;
    history.push(path);
  }
  render() {
    return (
      <section id="sectionAbout" className="text-center">
        <div id="masthead">
          <picture>
            <img
              height="142"
              width="1048"
              src={MillersvilleLogo}
              alt="Millersville University"
            />
          </picture>
          <h1>
            <span id="logoArts">Arts</span>&nbsp;
            <span id="logoMU">MU</span>&nbsp;
            <span id="logoLive">LIVE!</span>
          </h1>
        </div>
        <p className="text-large">
          As a patron of the performing arts, you deserve to get the most out of
          every night at our venues.{" "}
          <span className="term" style={{ whiteSpace: "nowrap" }}>
            Arts MU Live!
          </span>{" "}
          is your all-access pass to an enhanced experience at shows presented
          by <span className="term">The Arts at Millersville University</span>.
        </p>
        <div id="aboutFeatures" className="section-skewed bg-gold">
          <ul>
            <li>
              <img
                className="about-illustration"
                src={LibraryImg}
                alt="Browsing the event schedule"
              />
              <h4>Paperless Programs</h4>
              <p>
                Collect <span className="term">Programs</span> from the shows
                you attend and browse your collection in your{" "}
                <span className="term">Library</span>.
              </p>
              {this.props.customUser ? (
                <button
                  className="cta cta-lg cta-light"
                  onClick={(e) => this.pushToHistory(e, "/profile")}
                >
                  Visit Your Profile
                </button>
              ) : (
                <button
                  className="cta cta-lg cta-light"
                  onClick={(e) => this.pushToHistory(e, "/signup")}
                >
                  Get Started
                </button>
              )}
            </li>
            <li>
              <img
                className="about-illustration"
                src={CalendarImg}
                alt="Browsing the event schedule"
              />
              <h4>Stay Up To Date</h4>
              <p>
                Explore this season&#39;s <span className="term">Schedule</span>
                , and <span className="term">Bookmark</span> upcoming events.
              </p>
              <button
                className="cta cta-lg cta-light"
                onClick={(e) => this.pushToHistory(e, "/events")}
              >
                View the Schedule
              </button>
            </li>
            <li>
              <img
                className="about-illustration"
                src={GamificationImg}
                alt="Collecting Badges"
              />
              <h4>Go for the Gold</h4>
              <p>
                Score <span className="term">Points</span>, collect{" "}
                <span className="term">Badges</span>, and climb the{" "}
                <span className="term">Leaderboard</span> to prove you&apos;re
                our #1 patron.
              </p>
              {this.props.customUser ? (
                <button
                  className="cta cta-lg cta-light"
                  onClick={(e) => this.pushToHistory(e, "/leaderboard")}
                >
                  View Leaderboard
                </button>
              ) : (
                <button
                  className="cta cta-lg cta-light"
                  onClick={(e) => this.pushToHistory(e, "/signup")}
                >
                  Get Started
                </button>
              )}
            </li>
            <li>
              <h4>Need some help?</h4>
              <p>
                No worries — we're new to this too. Let's get you up to speed.
              </p>
              <button
                className="cta cta-lg cta-light"
                onClick={(e) => this.pushToHistory(e, "/help")}
              >
                How it Works
              </button>
            </li>
          </ul>
        </div>
        <picture id="aboutThisSeason" className="section-skewed">
          <source srcSet={ArtsMULogoLg} media="(min-width: 600px)" />
          <img src={ArtsMULogoSm} alt="The Arts at Millerville Logo" />
        </picture>
        <div id="about-blurb" className="sub-section">
          <h2 className="fixed-header">This Season</h2>
          <p className="text-extra-large">
            The Arts at Millersville is committed to our community and our goal to be the home of the cultural arts for both the University and Lancaster County. Our Performing Arts Series will offer music, dance, theater, and a variety of family-friendly and community-centered presentations at the Ware Center and/or the Winter Visual and Performing Arts Center.
          </p>
        </div>
        <div className="section-skewed promo">
          <img width="64" src={ScheduleIcon} alt="Schedule icon" />
          <h1>Upcoming Events</h1>
          <button
            className="cta cta-lg cta-light"
            onClick={(e) => this.pushToHistory(e, "/events")}
          >
            View the Schedule
          </button>
        </div>
      </section>
    );
  }
}

export default withRouter(About);
