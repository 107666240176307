import React from "react";
import firebase from "../firebase";
import moment from "moment";
import ConfirmButtons from "./confirmButtons";
import QRCode from "qrcode.react";

class EventModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmButtonsActive: 0,
      options: [],
      copySource: 0,
    };
    this.confirmCopy = this.confirmCopy.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  confirmCopy() {
    // get programs from database
    // get source program
    console.log("copy source", this.state.copySource, "in", this.props.schedule);
    let sourceId = this.props.schedule[this.state.copySource].id;
    console.log("source id", sourceId);
    const sRef = firebase.database().ref("programs/" + sourceId);
    sRef.once("value").then((snapshot) => {
      const sProgram = snapshot.val();
      console.log("got source program", sProgram.id);
      // get target program
      let targetId = this.props.manager.state.eventToModal.id;
      let targetPath = this.props.manager.state.eventToModal.path;
      const pRef = firebase.database().ref("programs/" + targetId);
      sProgram.id = targetId;
      sProgram.path = targetPath;
      // copy - set program in database
      pRef.set(sProgram);
      // set program in front end
      this.props.app.updateProgramInApp(sProgram);
      this.props.app.readAllProgramData();
      // close modal
      this.props.manager.setState({
        eventModalActive: false,
        eventToModal: {}
      })
    });

    
  }
  populateMenu() {
    let newOptions = [...this.props.schedule];
    if (newOptions.length > 10) {
      newOptions.reverse();
      newOptions.slice(0, 10);
      newOptions.reverse();
    } 
    
    this.setState({
      options: newOptions,
    });
  }

  downloadQRCode() {
    const canvas = document.getElementById("qr-canvas");
    const pngURL = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngURL;
    downloadLink.download = "qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: parseInt(e.target.value),
    });
  }

  componentDidMount() {
    this.populateMenu();
  }

  render() {
    var getOptions = this.state.options.map((opt, key) => {
      let dateString = moment(opt.startTime).format("MMM D/YY");
      let titleString = opt.title;
      if (titleString.length > 50) {
        titleString = titleString.substring(0, 50);
        titleString += "...";
      }
      return (
        <option key={key} value={key}>
          {dateString} · {titleString}
        </option>
      );
    });

    return (
      <div
        id="event-modal"
        className={this.props.active ? "modal hiding active" : "modal hiding"}
      >
        <div>
          <h1>Copy Past Program</h1>
          <p>
            You can copy the <span className="term">Program</span> from a recent{" "}
            <span className="term">Event</span> and use it as a template to
            replace this <span className="term">Event</span>'s{" "}
            <span className="term">Program</span>.
          </p>
          {this.state.options && (
            <select
              name="copySource"
              value={this.state.copySource}
              onChange={this.handleChange}
            >
              {getOptions}
            </select>
          )}

          <ConfirmButtons
            manager={this}
            active={this.state.confirmButtonsActive}
            first={"Copy"}
            second={"Confirm Copy"}
            callback={this.confirmCopy}
          />

          <h1>QR Link</h1>
          <p>Use this QR code to link patrons to the <span className="term">Program</span> for this <span className="term">Event</span>.</p>
          {this.props.manager.state.eventToModal !== null &&
            <>
              <QRCode 
              id="qr-canvas"
              renderAs="canvas"
              value={`https://artsmulive.com/events/${this.props.manager.state.eventToModal.path}/program`} 
              onClick={this.downloadQRCode} />
              <p className="centered">Click QR Code to Download</p>
            </>
          }
          <button
            className="cta"
            onClick={(e) => this.props.manager.setModalActive(false)}
          >
            Back to Schedule
          </button>
        </div>
      </div>
    );
  }
}

export default EventModal;
