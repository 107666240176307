import React, { useState, useEffect } from "react";
import * as serviceWorker from "../serviceWorker";

export default function Updater() {
  // whether to show the reload modal
  const [showReload, setShowReload] = useState(false);
  // store the updated service worker waiting to be used
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = (registration) => {
    console.log("service worker update");

    // show reload modal
    setShowReload(true);
    // grab updated service worker from registration
    setWaitingWorker(registration.waiting);
  };

  const reloadPage = () => {
    // post message to updated service worker
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
    // hide modal
    setShowReload(false);

    window.location.reload(true);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  return (
    <div
      id="updater"
      className={showReload ? "modal hiding active" : "modal hiding"}
    >
      <h1>
        <span id="logoArts">Arts</span>&nbsp;
        <span id="logoMU">MU</span>&nbsp;
        <span id="logoLive">LIVE!</span>
      </h1>
      <p>A new app update is ready.</p>
      <button className="cta" onClick={reloadPage}>
        Let's Go
      </button>
    </div>
  );
}
