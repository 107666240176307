import React from 'react';
import { withRouter } from 'react-router-dom';

class ResetScroll extends React.Component {
  componentDidUpdate(prevProps) {
    // If location has changed
    if (this.props.location !== prevProps.location && 
      // and we're not inside /events
      !this.props.location.pathname.includes('/events/')) {
      // Scroll to top
      window.scrollTo(0, 0)
    }
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(ResetScroll);