import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import DateInput from './dateInput';

class SignUpStart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      birthdaymonth: '',
      birthdayday: '',
      birthdayyear: '',
    }
  }
  pushToHistory(e, path) {
    this.props.history.push(path);
  }
  clickSkip(e) {
    this.props.signUp.setState({
      step: 2,
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    // Check that user is 13+ years old
    var birthday = moment().year(this.state.birthdayyear)
      .month(this.state.birthdaymonth - 1).date(this.state.birthdayday);
    const diff = moment().diff(birthday, 'years');
    // Don't let anyone 12 or younger register
    if (diff < 13) {
      const ageError = new Error('You must be at least 13 years old to create an account.');
      this.props.signUp.handleError(ageError);
    }
    // Otherwise create user
    else {
      this.props.app.setState({
        newBirthday: birthday.format()
      });
      let auth = this.props.signUp.props.firebase.auth();
      auth.createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then((result) => {
        this.props.signUp.setState({
          step: 1,
        });
      })
      .catch((error) => {
        this.props.signUp.handleError(error);
      });
    }
  }
  componentDidMount() {
    const now = moment();
    this.setState({
      birthdayday: now.date(),
      birthdaymonth: now.month(),
      birthdayyear: now.year()
    });
  }
  render() {
    return (
      <div className="standard-page">
        <h1>Sign Up</h1>
        <p>Use your email address to sign up for a free account. You can create a <span className="term">Profile</span> and use it to collect a <span className="term">Library</span> of <span className="term">Programs</span>. Just enter your email address and a secure password below.</p>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <label>
            Email
            <input 
              required 
              id="email" 
              name="email" 
              type="email" 
              placeholder="your@email.address" 
              value={this.state.email} 
              onChange={(e) => this.handleChange(e)} />
          </label>
          <label>
            Password
            <input 
              required 
              id="password" 
              name="password" 
              type="password" 
              placeholder="securepassword" 
              value={this.state.password} 
              onChange={(e) => this.handleChange(e)} />
          </label>
          <label className="date-inputs">
            Date of Birth (MM/DD/YYYY)
            <span>
            <DateInput 
              manager={this} 
              name="birthday" />
              </span>
          </label>
          <input 
            id="submit" 
            name="submit" 
            type="submit" 
            value="Submit" />
        </form>
        <div className="section-skewed promo text-center">
          <h2>Already have an account?</h2>
          <button
            className="cta cta-light"
            onClick={(e) => this.pushToHistory(e, '/login')}>
            Log In
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(SignUpStart);