import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import OfferingsRow from './offeringsRow';
import ElementsList from './elementsList';
import SponsorsLockup from './sponsorsLockup';
import RecsList from './recsList';

class ProgramRead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventPath: '',
      event: {},
      program: {},
      recommendations: [],
    };
  }
  clickPrint() {
    window.print();
  }
  pushToHistory(e, path) {
    const history = this.props.history;
    history.push(path);
  }
  getIDFromPath(path) {
    const e = this.props.app.state.schedule.find((event) => {
      return event.path === path;
    });
    return e.id;
  }
  addProgramToLibrary(e) {
    if (this.props.app.state.customUser) {
      const id = this.getIDFromPath(this.state.eventPath);
      var customUser = this.props.app.state.customUser;
      this.props.app.addToLibrary(customUser, id); 
      // Toast
      const toast = this.props.app.makeNewToast('Program added to Library.');
      this.props.app.pushToast([toast]);
      // Update customUser in front end & database
      this.props.app.updateUserData(customUser);
    } else {
      this.pushToHistory({}, '/signup');
    }
  }
  getMonthAndDate(d) {
    var m = moment(d);
    var abbr = m.month() === 4 ? false : true;
    if (abbr === true)
      return m.format('MMM. D, YYYY');
    else
      return m.format('MMM D, YYYY');
  }
  getDay(d) {
    return moment(d).format('ddd.,');
  }
  getTime(d) {
    const m = moment(d);
    var hours = m.hours();
    var mins = m.minutes();
    var ampm = 'AM';
    if (hours === 0)
      hours = 12;
    else if (hours > 11) {
      ampm = 'PM';
    }
    if (hours > 12) {
      hours -= 12;
    }
    if (mins < 10) {
      mins = '0' + mins;
    }
    return hours + ':' + mins + ' ' + ampm;
  }
  loadProgramData() {
    // Grab route params
    const { match: { params } } = this.props;
    // Cache program, event, and event path in state
    const id = this.getIDFromPath(params.eventPath);
    const program = this.props.app.state.programs.find((p) => p.id === id);
    const event = this.props.app.state.schedule.find((e) => e.id === id);
    this.getRecommendations(event);
    this.setState({
      eventPath: params.eventPath,
      program: program,
      event: event,
    });
    if (program.elements.length > 0)
      this.cacheProgram(program);
  }
  checkForCachedProgram(path) {
    var programCache = JSON.parse(localStorage.getItem('programcache'));
    console.log('parsed program cache:', programCache);
    var cacheError = false;
    if (programCache === null || programCache === undefined)
      cacheError = true;
    /*for (const p of programCache) {
      if (p === null || p === undefined)
        cacheError = true;
    }*/
    if (cacheError === true) {
      programCache = [];
      localStorage.removeItem('programcache');
    }
    else {
      const cached = programCache.find((p) => p.path === path);
      if (cached) {
        this.setState({
          program: cached
        });
      }
    }    
  }
  cacheProgram(program) {
    // Cache program to local storage
    var repeat = false;
    var programCache = JSON.parse(localStorage.getItem('programcache'));
    // If cache is empty
    if (!programCache)
      programCache = [];
    // If cache is not empty
    else {
      // Check all cached programs for repeats
      programCache.forEach((p) => {
        if (p && p.id === program.id) {
          repeat = true;
        }
      });
    }
    if (!repeat) {
      // Cache program if it's not a repeat
      programCache.push(program);
    }
    var stringProgramCache = JSON.stringify(programCache);
    localStorage.setItem('programcache', stringProgramCache);
  }
  getRecommendations(event) {
    var recommendations = [];
    // Recs based on tags
    this.props.app.state.schedule.forEach((e) => {
      event.tags.forEach((t) => {
        if (e.tags.includes(t) && recommendations.length < 3 && e.published &&
          e.id !== event.id) {
          recommendations.push(e);
        }
      });
    });
    // Recs based on genre
    if (recommendations.length < 3) {
      this.props.app.state.schedule.forEach((e) => {
        if (e.genre === event.genre && recommendations.length < 3 && e.published && 
          e.id !== event.id) {
          recommendations.push(e);
        }
      });
    }
    this.setState({
      recommendations: recommendations
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const { match: { params } } = this.props;
    this.checkForCachedProgram(params.eventPath);
    if (this.props.app.state.schedule.length < 1)
      this.props.app.readAllEventData();
    if (this.props.app.state.programs.length < 1)
      this.props.app.readAllProgramData();
    else
      this.loadProgramData();
    if (this.props.app.state.sponsors.length < 1) 
      this.props.app.readAllSponsorData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.programs !== prevProps.programs) {
      if (this.props.app.state.schedule.length > 0 && this.props.programs.length > 0) {
        this.loadProgramData();
      }
    }
  }
  render() {
    return (
      <>
      {this.state.program && this.state.event && 
        <section id="program">
          {!this.props.app.state.customUser && 
            <div id="section-program-add" className="bg-gold">
              <p className="text-center">Log in to your <span className="term">Profile</span> or <span className="term">Sign Up</span> for free today to add <span className="term">Programs</span> to your <span className="term">Library</span>, <span className="term">Bookmark</span> shows, and collect <span className="term">Badges</span>!</p>
              <button 
                className="cta cta-lg cta-light button-icon add-library-btn" 
                onClick={(e) => this.addProgramToLibrary(e)}>
                Add to Library
              </button>
            </div>
          }
          {this.props.app.state.customUser && this.state.eventPath.length > 0 && 
            <>
              {this.props.app.state.customUser.library.includes(this.getIDFromPath(this.state.eventPath)) ? 
                <h6 className="text-center">In Library</h6>
                :
                <div id="section-program-add" className="bg-gold">
                  <button 
                    className="cta cta-lg cta-light button-icon add-library-btn" 
                    onClick={(e) => this.addProgramToLibrary(e)}>
                    Add to Library
                  </button>
                </div>
              }
            </>
          }
          {!this.props.app.state.liveMode && 
            <picture id="programHero">
              <img width="800" alt={this.state.event.title} src={this.state.event.coverPhotoURL} />
            </picture>
          }
          <span id="programInfo">
            <ul className="eventTags">
              <li>
                <p>{this.state.event.genre}</p>
              </li>
            </ul>
            <h1 id="eventName">{this.state.event.title}</h1>
            <h2 id="eventLocal">
              <span id="eventLocalPart1">{this.state.event.locationDetail}</span><span>|</span>
              <span id="eventLocalPart2">{this.state.event.locationMain}</span>
            </h2>
          </span>
          <div className="print-program-btn">
            {!this.props.app.state.liveMode && 
              <button className="cta cta-small" onClick={this.clickPrint}>
                Print this Program
              </button>
            }
          </div>
          <div id="eventIntro">
            <div id="showtime" className="sub-section">
              <h2 className="fixed-header">Showtime</h2>
              <p className="eventDateLong"> {this.getDay(this.state.event.startTime)} {this.getMonthAndDate(this.state.event.startTime)}</p>
              <p className="eventTime">{this.getTime(this.state.event.startTime)}</p>
              {this.state.event.accessibility && 
                <OfferingsRow offerings={this.state.event.accessibility} />
              }
            </div>
            {/*
            <div id="sectionAccessibility" className="section-framed">
              {this.state.event.accessibility && 
                <OfferingsRow offerings={this.state.event.accessibility} />
              }
            </div>
            */}
            <div id="programAbout" className="sub-section">
              <h2 className="fixed-header">About</h2>
              <p>{this.state.event.description}</p>
            </div>
          </div>
          {this.state.program.elements && 
            <div className="sub-section">
              <ElementsList 
                manager={this} 
                elements={this.state.program.elements} 
                liveMode={this.props.app.state.liveMode} />
            </div>
          }
          <SponsorsLockup 
            sponsors={this.props.app.state.sponsors} 
            liveMode={this.props.app.state.liveMode} />
          <div id="printProgram" className="text-center">
            <h4>Printable Programs</h4>
            <p>If this <span className="term">Program</span> is in your <span className="term">Library</span>, you'll be able to come back and read it any time. You can even print it at home! This page is automatically formatted for printing, so go ahead and create a paper keepsake.</p>
            {!this.props.app.state.liveMode && 
              <div className="print-program-btn">
                <button className="cta cta-small" onClick={this.clickPrint}>
                  Print this Program
                </button>
              </div>
            }
          </div>
          {this.props.app.state.customUser && this.state.eventPath.length > 0 && 
            this.state.recommendations.length > 0 && 
            !this.props.app.state.liveMode && 
            <div id="relatedEvents" className="section-skewed bg-gold">
              <h1 className="text-center">Related Events</h1>
              <RecsList recommendations={this.state.recommendations} />
            </div>
          }
        </section>
      }
      </>
    );
  }
}

export default withRouter(ProgramRead);