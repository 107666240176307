import React from 'react';
import GeneralMark from '../img/icons/general-mark.svg';

class FileInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: '',
    };
  }
  getFileURL() {
    const fileRef = this.props.storageRef.child(this.props.path);
    fileRef.getDownloadURL()
      .then((url) => {
        this.setState({
          imgSrc: url,
        });
      });
  }
  componentDidMount() {
    this.getFileURL();
  }
  render() {
    return (
      <div className="file-info" onClick={(e) => this.props.callback(e, this.state.imgSrc)}>
        <picture>
          <img src={this.state.imgSrc} alt={'Image from ' + this.state.imgSrc}/>
        </picture>
        <p>
          {this.props.general && 
            <span><img className="general-mark" src={GeneralMark} alt="general" /></span>
          }
          {this.props.name}
        </p>
      </div>
    );
  }
}

export default FileInfo;