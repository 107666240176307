import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminApp from './adminApp';
import UserRead from './lib/userRead';
import UserWrite from './lib/userWrite';
import DeleteAccount from './lib/deleteAccount';

function PatronApp(props) {
  return (
    <>
      <Switch>
        <Route path="/profile/settings/delete">
          <DeleteAccount app={props.app} user={props.app.state.user} />
        </Route>
        <Route path="/profile/settings">
          <UserWrite app={props.app} />
        </Route>
        <Route path="/profile">
          <UserRead app={props.app} customUser={props.customUser} />
        </Route>
      </Switch>
      {props.customUser.admin &&
        <AdminApp app={props.app} />
      }
    </>
  );
}

export default PatronApp;