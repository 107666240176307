import firebase from './firebase';

export const getMessagingAndToken = async () => {
  try {
    //console.log('try to get messaging obj and token');
    const messaging = firebase.messaging();
    //await messaging.requestPermission();
    const token = await messaging.getToken();
    //console.log('user token:', token);
    return { messaging, token };
  } 
  catch (err) {
    console.log('An error occurred while retrieving token. ', err);
    return null;
  }
}