import React from 'react';
import firebase from '../firebase';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collection: '',
      storage: null,
      storageRef: null,
      spinner: false,
    };
    this.clickCancel = this.clickCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }
  getChildStorageRef(storageRef, path) {
    console.log('get child storage ref:', path);
    return storageRef.child(path);
  }
  clickCancel(e) {
    document.getElementById('upload-form').reset();
    this.props.manager.setState({ 
      fileUploadActive: false 
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit(e) {
    e.preventDefault();

    if (this.fileInput.current.files[0]) {
      this.setState({
        spinner: true,
      });
      // Set profile pic size limit in bytes
      const sizeLimit = 7340032; // 7MB
      const file = this.fileInput.current.files[0];
      //const contextSubstring = this.props.context.substring(0, 11);
      // File type
      var fileType = file.type.substring(0, 5) === 'image' ? true : false;
      // File size
      var fileSize = true;
      // Check file size against limit for profile pics
      if (this.props.context === 'userWrite' && file.size > sizeLimit) {
        fileSize = false;
      }
      if (fileType && fileSize) {
        if (this.props.context === 'userWrite') {
          this.clearOldProfilePics(this.state.storageRef);
        }
        const childStorageRef = this.getChildStorageRef(this.state.storageRef, this.state.collection + '/' + file.name);
        childStorageRef.put(file)
          .then((snapshot) => {
            this.props.callback(snapshot);
          });
      }
      else if (!fileSize) {
        this.clickCancel({});
        this.props.app.setState({
          modalActive: true,
          modalData: {
            heading: 'Wrong file size',
            paragraph: 'Please upload a profile picture that is no more than 7 MB in size. Remember you can set your profile pic from mobile or desktop!',
            cta: 'OK',
          },
        })
      }
      else if (!fileType) {
        this.clickCancel({});
        this.props.app.setState({
          modalActive: true,
          modalData: {
            heading: 'Wrong file type',
            paragraph: `That file wasn't an image. Only image files may be uploaded.`,
            cta: 'OK'
          }
        });
      }
    }
    else {
      this.props.app.setState({
        modalActive: true,
        modalData: {
          heading: 'A small problem',
          paragraph: `You haven't selected a file to upload!`,
          cta: 'OK'
        }
      });
    }
  }
  clearOldProfilePics(storageRef) {
    var listRef = storageRef.child(this.state.collection);
    // Find all the stored items
    listRef.listAll().then((res) => {
      // For each item
      res.items.forEach((itemRef) => {
        // Delete the file
        itemRef.delete().then(() => {
          this.props.manager.getList(this.props.manager.state.pathRef);
        })
        .catch((error) => {
          console.log('error while deleting old pic:', error);
        });
      });
    })
    .catch((error) => {
      console.log('error while getting list of old pics', error);
    });

  }
  loadPath() {
    if (this.props.path) {
      console.log('set path:', this.props.path);
      this.setState({
        collection: this.props.path
      });
    } else {
      console.log('set path__:', `profilePics/${this.props.app.state.customUser.uid}`);
      this.setState({
        collection: `profilePics/${this.props.app.state.customUser.uid}`
      });
    }
  }
  componentDidMount() {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    this.setState({ 
      storage, 
      storageRef 
    });
    this.loadPath();
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.loadPath();
    }
  }
  render() {
    return (
      <div 
        id="file-upload" 
        className={this.props.manager.state.fileUploadActive ? 'hiding active standard-page text-center' : 'hiding standard-page text-center'}>
        <h2>File Upload</h2>
        {this.state.spinner ? 
          <div className="spinner"></div>
          :
          <>
            <form id="upload-form" onSubmit={this.handleSubmit}>
            <label className="label-for-file">
              Choose a file to upload
              <input 
                type="file" 
                ref={this.fileInput} 
                id="input" />
            </label>
            {this.props.app.state.customUser.admin && // Admin can see collection select 
              this.props.context !== 'userWrite' && 
              <>
                <label htmlFor="collection">Add to collection
                  <select 
                    required 
                    id="collection"
                    name="collection" 
                    value={this.state.collection} 
                    onClick={(e)=>console.log('this.props.context---', this.props.context)}
                    onChange={(e) => this.handleChange(e)}>
                    {this.props.context === 'eventWrite' || this.props.context === 'programWrite' ? 
                      <option value='general'>General</option>
                      :
                      null
                    }
                    {this.props.context === 'eventWrite' && 
                      <option value={this.props.path}>This event</option>
                    }
                    {this.props.context.substring(0, 7) === 'events/' &&
                      <option value={this.props.path}>This event</option>
                    }
                    {this.props.context === 'sponsorWrite' && 
                      <option value={this.props.path}>Partners</option>
                    }
                  </select>
                </label>
                {this.props.context === 'eventWrite' || this.props.context === 'programWrite' ? 
                  <div>
                    <p>Images uploaded to the <span className="term">General</span> collection will be available everywhere. Use the <span className="term">General</span> collection for photos that you want to reuse across multiple <span className="term">Events</span> or <span className="term">Programs</span>.</p>
                  </div>
                  :
                  null
                }
                {this.props.context === 'sponsorWrite' && 
                  <p>Crop partner logos to remove most of the white space surrounding the logos themselves. Ideally partner logos should not exceed <strong>800 px</strong> in width or height or <strong>175 kB</strong> in file size. Your designer will be equipped to resize and compress images if necessary.</p>
                }
                {this.props.context === 'eventWrite' && 
                  <div>
                    <p>Most event photos should not exceed <strong>800 px</strong> in width or height or <strong>175 kB</strong> in file size. Only make exceptions for photos that demand especially pristine treatment. <span className="term">Cover photos</span> will be automatically cropped into their centers to fit different screen sizes. Your designer will be equipped to resize and compress images if necessary.</p>
                  </div>
                }
              </>
            }
            <input 
              type="submit" 
              value="Upload File" />
          </form>
          <button className="cta cta-trans" onClick={this.clickCancel}>Cancel</button>
          </>
        }
      </div>
    );
  }
}

export default FileUpload;