import React from 'react';
import BadgeDisplay from './badgeDisplay';

function BadgesList(props) {
  var badgesList = props.badges.map((b, key) => {
    return ( 
      <li key={key}>
        <BadgeDisplay 
          badge={b} 
          size={props.size} 
          description={props.description} />
      </li> 
    );
  });
  return (
    <ul id="badgeList" data-child-count={props.badges.length}>
      {badgesList}
    </ul>
  );
}

export default BadgesList;