import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

function BookmarksList(props) {
  const getTitleFromID = (id) => {
    const e = props.app.state.schedule.find((event) => {
      return event.id === id;
    });
    return e.title;
  }
  const getDateFromID = (id) => {
    const e = props.app.state.schedule.find((event) => {
      return event.id === id;
    });
    const m = moment(e.startTime);
    return m.format('MMM D');
  }
  const getTimeFromID = (id) => {
    const e = props.app.state.schedule.find((event) => {
      return event.id === id;
    });
    const m = moment(e.startTime);
    return m.format('h:mm A');
  }
  const getPathFromID = (id) => {
    const e = props.app.state.schedule.find((event) => {
      return event.id === id;
    });
    return e.path;
  }
  const pushToHistory = (e, title) => {
    window.scrollTo(0, 0);
    const path = title.replace(/\s+/g, '');
    const history = props.history;
    history.push('/events/'+ path);
  }
  var bookmarksList = props.bookmarks.map((id, key) => {
    var obj = props.app.state.schedule.find((event) => {
      return event.id === id;
    });
    if (obj) {
      return (
        <li key={id}>
          <button 
            onClick={(e) => pushToHistory(e, getPathFromID(id))}>
            {getTitleFromID(id)}<br/>
            <span className="eventDay">{getDateFromID(id)}</span>
            <span className="eventTime">{getTimeFromID(id)}</span>
          </button>
        </li>
      );
    }
    else {
      props.app.removeBookmark(id);
      return null;
    }
    
  });
  return (
    <>
      {props.bookmarks.length > 0 ?
        <ul>
          {bookmarksList}
        </ul>
        :
        <>
          <h2 className="text-center">There&#39;s nothing here.</h2>
          <p className="text-center">Your haven&#39;t <span className="term">Bookmarked</span> anything! <span className="term">Bookmark</span> shows that you&#39;re interested in to get reminders and see shortcuts from your <span className="term">Program</span> to your <span className="term">Bookmarked</span> shows.</p>
          <button 
            className="cta" 
            onClick={(e) => pushToHistory(e, '')}>
            Browse the Schedule
          </button>
        </>
      }
    </>
  );
}

export default withRouter(BookmarksList);