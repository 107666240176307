import React from 'react';
import { withRouter } from 'react-router-dom';
import firebase from '../firebase';
import Breadcrumbs from './breadcrumbs';
import ElementWrite from './elementWrite';
import ElementsList from './elementsList';

class ProgramWrite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      programToWrite: {},
      eventTitle: '',
      eventPath: '',

      elementWriteActive: false,
      elementToWrite: {},
    };
    this.browserCallback = this.browserCallback.bind(this);
    this.openFileBrowser = this.openFileBrowser.bind(this);
  }
  browserCallback() {
    console.log('OK');
  }
  openFileBrowser() {
    this.props.app.setState({
      fileBrowserActive: true
    });
  }
  // C.R.U.D.
  createElement(element) {
    var programElements = this.state.programToWrite.elements || [];
    programElements.push(element);
    const program = this.makeNewProgram(
      this.state.programToWrite.id, 
      programElements, 
      this.state.eventPath
    );
    // Set program in database & front end
    const pRef = firebase.database().ref('programs/' + program.id);
    pRef.set(program);
    this.props.app.updateProgramInApp(program);
    // Open editor
    this.setState({
      elementWriteActive: true,
      elementToWrite: element,
    });
  }
  updateElement(element) {
    // set element in program
    var programElements = this.state.programToWrite.elements || [];
    const index = programElements.findIndex((el) => el.id === element.id);
    programElements[index] = element;
    const program = this.makeNewProgram(
      this.state.programToWrite.id, 
      programElements, 
      this.state.eventPath
    );
    // Toast
    const toast = this.props.app.makeNewToast('Program data updated.');
    this.props.app.pushToast([toast]);
    // set program in database
    const pRef = firebase.database().ref('programs/' + program.id);
    pRef.set(program);
    // set program in front end
    this.props.app.updateProgramInApp(program);
  }

  addBlankElement() {
    const id = new Date().toString();
    const element = this.makeNewElement(id, 'headinglarge');
    this.createElement(element);
  }
  // Button callback for New Event button
  clickNewElement(e) {
    this.addBlankElement();
    this.props.app.readAllProgramData();
  }
  // Programs
  // new Program
  makeNewProgram(id, elements, path) {
    const program = {
      id: id,
      elements: elements,
      path: path
    };
    return program;
  }
  // Elements
  // new element
  makeNewElement(id, eType, innerHTML = null, url = null, alt = null, caption = null) {
    const newElement = {
      id: id,
      eType: eType,
      innerHTML: innerHTML,
      url: url,
      alt: alt,
      caption: caption 
    };
    return newElement;
  }
  deleteElement(id) {
    var programElements = this.state.programToWrite.elements || [];
    const index = programElements.findIndex((el) => el.id === id);
    programElements.splice(index, 1);
    const program = this.makeNewProgram(
      this.state.programToWrite.id, 
      programElements, 
      this.state.eventPath
    );
    // Set program in database & front end
    const pRef = firebase.database().ref('programs/' + program.id);
    pRef.set(program); 
    this.props.app.updateProgramInApp(program);
  }
  arrayMove(array, startIndex, endIndex) {
    if (endIndex >= array.length) {
        var k = endIndex - array.length + 1;
        while (k--) {
            array.push(undefined);
        }
    }
    array.splice(endIndex, 0, array.splice(startIndex, 1)[0]);
    return array; // for testing
  };
  moveElement(direction, element) {
    var programElements = this.state.programToWrite.elements || [];
    var index = programElements.findIndex((el) => el.id === element.id);
    if (direction === 'up' && index > 0)
      this.arrayMove(programElements, index, index - 1);
    else if (direction === 'down' && index < programElements.length - 1)
      this.arrayMove(programElements, index, index + 1);
    const program = this.makeNewProgram(
      this.state.programToWrite.id, 
      programElements, 
      this.state.eventPath
    );
    // set program in database
    const pRef = firebase.database().ref('programs/' + program.id);
    pRef.set(program);
    // set program in front end
    this.props.app.updateProgramInApp(program);
    this.props.app.readAllProgramData();
  }
  getEventIDWithPath(path) {
    const event = this.props.app.state.schedule.find((e) => e.path === path);
    return event.id;
  }
  getEventTitleWithPath(path) {
    const event = this.props.app.state.schedule.find((e) => e.path === path);
    return event.title;
  }
  getProgramWithEventPath(path) {
    // find event
    const event = this.props.app.state.schedule.find((e) => e.path === path);
    // lookup program with id
    var program = this.props.app.state.programs.find((p) => event.id === p.id);
    // May need to create program
    if (program === undefined)
      program = this.makeNewProgram(event.id, [], path);
    return program;    
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // Make sure we have program data
    if (this.props.programs.length < 1)
      this.props.app.readAllProgramData();
    // Check for the program
    // Grab route params
    const { match: { params } } = this.props;
    // Cache program in state via path
    const eventTitle = this.getEventTitleWithPath(params.eventPath);
    const programToWrite = this.getProgramWithEventPath(params.eventPath);
    const eventID = this.getEventIDWithPath(params.eventPath);
    this.setState({
      programToWrite: programToWrite,
      eventTitle: eventTitle,
      eventPath: params.eventPath
    });
    this.props.app.setState({
      fileBrowserData: {
        //context: 'events/' + eventID,
        context: 'eventWrite', 
        path: 'events/' + eventID, 
        callback: this.browserCallback
      }
    });
  }
  render() {
    return (
      <div className={this.props.app.state.fileBrowserActive ? 
        'standard-page hiding' : 'standard-page hiding active'}>
        {this.state.elementWriteActive ? 
          <ElementWrite 
            app={this.props.app} 
            manager={this} 
            elementToWrite={this.state.elementToWrite} />
          :
          <>
            <Breadcrumbs path={'/dashboard/events'} innerHTML={'Edit Schedule'}/>
            <div id="editProgram">
              <h2 className="text-center">Edit Program For</h2>
              <h3 className="text-center">{this.state.eventTitle}</h3>
              <p>Here you can compose a <span className="term">Program</span> for this event using <span className="term">Elements</span>. Different types of <span className="term">Elements</span> display different content.</p>
              <p>Open the <span className="term">File Browser</span> to see the images associated with this <span className="term">Event</span> and upload more for use in this <span className="term">Program</span>.</p>
              <button
                id="profAddPic"
                className="cta cta-small button-icon cta-tool cta-trans" 
                onClick={this.openFileBrowser}>
                Open File Browser
              </button>
              {this.state.programToWrite && this.state.programToWrite.elements && 
                <ElementsList 
                  manager={this} 
                  elements={this.state.programToWrite.elements} 
                  write />
              }
              <button 
                className="cta" 
                onClick={(e) => this.clickNewElement(e)}>
                Add Element
              </button>
            </div>
          </>
        }
        
      </div>
    );
  }
}

export default withRouter(ProgramWrite);