import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ScheduleWrite from './lib/scheduleWrite';
import Sponsors from './lib/sponsors';
import Tags from './lib/tags';
import UsersAll from './lib/usersAll';

function AdminApp(props) {
  return (
    <>
      <Switch>
        <Route path="/dashboard/events">
          <ScheduleWrite app={props.app} schedule={props.app.state.schedule} />
        </Route>
        <Route path="/dashboard/sponsors">
          <Sponsors app={props.app} firebase={props.firebase} sponsors={props.app.state.sponsors} />
        </Route>
        <Route path="/dashboard/tags">
          <Tags app={props.app} />
        </Route>
        <Route path="/dashboard/users">
          <UsersAll app={props.app} />
        </Route>
      </Switch>
    </>
  );
}

export default AdminApp;