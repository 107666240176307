import React from 'react';
import { withRouter } from 'react-router-dom';

function RecsList(props) {
  var recsList = props.recommendations.map((r, key) => {
    return (
      <li className="recommendation"
        key={r.id} 
        onClick={(e) => props.history.push(`/events/${r.path}`)}>
        <div className="event-cover">
        <div>
	        <h2>{r.title}</h2>
          <picture>
            <img width="768" alt={r.title} src={r.coverPhotoURL} />
          </picture>
          </div>
        </div>
      </li>
    );
  });
  return (
    <ul id="relatedEventsList" data-child-count="1">
      {recsList}
    </ul>
  );
}

export default withRouter(RecsList);