import React from 'react';
import { withRouter } from 'react-router-dom';

function SignUpFinish(props) {
  return (
    <div className="standard-page">
      <h1>You&#39;re all set!</h1>
      <p>Check out your newly created <span className="term">Profile</span> or head over to the <span className="term">Schedule</span> to <span className="term">Bookmark</span> shows and collect programs.</p>
      <div className="section-skewed promo">
        <h1>Upcoming Events</h1>
    	  <button className="cta cta-light" onClick={(e) => props.history.push('/events')}>View the Schedule</button>
      </div>
    </div>
  );
}

export default withRouter(SignUpFinish);