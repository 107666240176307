import React from 'react';

function TagList(props) {
  var tagList = props.tags.map((t, key) => {
    return ( 
      <li key={t.name} className="tag">
        {t.name}
        {props.write && 
          <button className="tag-delete" onClick={(e) => props.manager.deleteTag(t.name)}>
            <span className="text-hidden">Delete</span>
          </button>
        }
      </li>
    );
  });
  return (
	<div id="tagList" className="text-center">
      <ul>
        {tagList}
      </ul>
    </div>
  );
}

export default TagList;