import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

class EventInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //qrCode: null,
      //qrActive: false,
    };
    this.clickRead = this.clickRead.bind(this);
    this.locate = this.locate.bind(this);
    this.locateSuccess = this.locateSuccess.bind(this);
    this.locateError = this.locateError.bind(this);
    this.getDistanceFrom = this.getDistanceFrom.bind(this);
  }
  pushToHistory(e, path) {
    const history = this.props.history;
    history.push(path);
  }
  openURL(e, url) {
    window.open(url, "_blank");
  }
  getMonthAndDate(d) {
    var m = moment(d);
    var abbr = m.month() === 4 ? false : true;
    if (abbr === true) return m.format("MMM. D, YYYY");
    else return m.format("MMM D, YYYY");
  }
  getDay(d) {
    return moment(d).format("dddd");
  }
  getTime(d) {
    return moment(d).format("h:mm A");
  }
  clickExpand(e) {
    var destination = this.props.write ? "/dashboard/events/" : "/events/";
    if (!this.props.expand) destination += this.props.event.path;
    this.pushToHistory({}, destination);
  }
  clickEventInfo(e) {
    console.error("click eventInfo");
    var val = "";
    if (this.props.write && !e.target.classList.contains("read-program-btn"))
      val += "/dashboard";
    if (this.props.expand && e.target.nodeName !== "BUTTON") {
      val += "/events";
    } else {
      val += "/events/" + this.props.event.path;
      if (
        e.target.classList.contains("read-program-btn") ||
        e.target.classList.contains("edit-program-btn")
      )
        val += "/program";
    }
    this.pushToHistory({}, val);
  }
  clickEdit(e) {
    var destination = "/dashboard/events/" + this.props.event.path;
    this.pushToHistory({}, destination);
    window.scrollTo(0, 0);
    this.props.manager.setState({
      eventWriteActive: true,
      eventToWrite: this.props.event,
    });
  }
  getTop(elem) {
    const box = elem.getBoundingClientRect();
    const body = document.body;
    const docEl = document.documentElement;
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const clientTop = docEl.clientTop || body.clientTop || 0;
    const top = box.top + scrollTop - clientTop;
    const topRound = Math.round(top) - 47;
    return topRound;
  }
  showReadButton() {
    var show = false;
    const customUser = this.props.app.state.customUser;

    if (customUser) {
      if (customUser.admin) {
        show = true;
      } else {		  
        if (customUser.library.includes(this.props.event.id)) {
          show = true;
        }
      }
    }

    if (
      !this.props.past &&
      this.props.app.state.featuredEvent &&
      this.props.app.state.featuredEvent.id === this.props.event.id
    ) {
      show = true;
    }
	
    return show;
  }
  clickRead() {
    if (parseInt(this.props.event.useGeolocation) >= 0) this.locate();
    else this.accessProgram();
  }
  locate() {
    if (!navigator.geolocation) {
      alert("geolocation not available");
    } else {
      navigator.geolocation.getCurrentPosition(
        this.locateSuccess,
        this.locateError
      );
    }
  }
  locateSuccess(position) {
    var location = this.props.event.useGeolocation;

    var onSite = this.getDistanceFrom(location, position.coords);
    if (!onSite) {
      onSite = this.getDistanceFrom(2, position.coords);
    }
    if (!onSite) {
      onSite = this.getDistanceFrom(3, position.coords);
    }
    if (
      this.props.app.state.customUser &&
      this.props.app.state.customUser.admin
    ) {
      this.accessProgram();
    } else if (onSite) {
      this.accessProgram();
    } else {
      const heading = "Where are you?";
      const message = `To access this Program, move closer to the venue. Sign up for a free account to add Programs to your Library and read them anywhere!`;
      this.props.app.setState({
        modalActive: true,
        modalData: {
          active: false,
          heading: heading,
          paragraph: message,
          cta: "OK",
        },
      });
    }
  }
  locateError() {
    const heading = "We get it.";
    const message = `Privacy is important! We use your location data only to confirm you're at the show that you're accessing the program for. We'll never save or share your location and we only check it momentarily. If you'd like to read the Program for this show, please refresh the page and grant permission for us to check your location. Thank you kindly!`;
    this.props.app.setState({
      modalActive: true,
      modalData: {
        active: false,
        heading: heading,
        paragraph: message,
        cta: "OK",
      },
    });
  }
  getDistanceFrom(location, coords) {
    console.log("get distance from: location", location, "coords", coords);
    const centers = [
      { latitude: 40.038659, longitude: -76.308337 }, // Ware
      { latitude: 40.0005462, longitude: -76.3585562 }, // Winter
      { latitude: 40.0465692, longitude: -76.3028907 }, // Lime st
      { latitude: 40.0465692, longitude: -76.3028907 }, // Lime st
    ];
    const radii = [
      { latitude: 0.000368, longitude: 0.000491 },
      { latitude: 0.000756, longitude: 0.000574 },
      { latitude: 0.000368, longitude: 0.000491 },
      { latitude: 0.000368, longitude: 0.000491 },
    ];
    const latDiff = Math.abs(coords.latitude - centers[location].latitude);
    const longDiff = Math.abs(coords.longitude - centers[location].longitude);
    const onSite =
      latDiff <= radii[location].latitude &&
      longDiff <= radii[location].longitude
        ? true
        : false;
    console.log("on site", onSite);
    return onSite;
  }
  accessProgram() {
    // Extra stuff for logged in users
    if (this.props.app.state.customUser) {
      if (
        !this.props.app.state.customUser.attendance.includes(
          this.props.event.id
        )
      ) {
        this.props.app.checkIn(
          this.props.event.id,
          this.props.app.state.customUser,
          this.props.event.startTime
        );
      }
    } else {
      this.props.app.setState({
        savedCheckIn: this.props.event.id,
      });
    }
    this.pushToHistory({}, "/events/" + this.props.event.path + "/program");
  }
  clickMoreOptions(e, event) {
    console.log("click more options on event", event);
    this.props.manager.setState({
      eventModalActive: true,
      eventToModal: event,
    });
  }

  componentDidMount() {
    if (this.props.expand) {
      const exp = document.querySelector("#" + this.props.event.path);
      window.scrollTo(0, this.getTop(exp));
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.expand !== this.props.expand) {
      if (this.props.expand) {
        const exp = document.querySelector("#" + this.props.event.path);
        window.scrollTo(0, this.getTop(exp));
      }
    }
  }

  render() {
    return (
      <div id={this.props.event.path}>
        {this.props.write && (
          <ul className="flex-cta-row edit">
            <li>
              <button
                className="cta cta-small cta-trans cta-tool"
                onClick={(e) => this.clickEdit(e)}
              >
                Edit Event
              </button>
            </li>
            <li>
              <button
                className="cta cta-small cta-trans cta-tool"
                onClick={(e) =>
                  this.pushToHistory(
                    e,
                    "/dashboard/events/" + this.props.event.path + "/program"
                  )
                }
              >
                Edit Program
              </button>
            </li>
            <li>
              <button
                className="cta cta-small cta-trans cta-tool"
                onClick={(e) => this.clickMoreOptions(e, this.props.event)}
              >
                ...
              </button>
            </li>
          </ul>
        )}
        {this.props.write && (
          <h6 className="text-center">
            {this.props.event.published ? (
              <span>Published</span>
            ) : (
              <span className="red-bg">Unpublished</span>
            )}
          </h6>
        )}
        <span className="dateInfo">
          <p className="eventDate">
            {this.getMonthAndDate(this.props.event.startTime)}
          </p>
          {!this.props.past && (
            <>
              <p className="eventDay">
                {this.getDay(this.props.event.startTime)}
              </p>
              <p className="eventTime">
                {this.getTime(this.props.event.startTime)}
              </p>
            </>
          )}
        </span>
        <h2 className="eventName">{this.props.event.title}</h2>
        <p className="eventLocal">
          <span>{this.props.event.locationDetail} |</span>
          <span> {this.props.event.locationMain}</span>
        </p>
        <ul className="eventTags">
          <li>
            <p className="event-tag">{this.props.event.genre}</p>
          </li>
        </ul>
        <div
          className={
            this.props.expand
              ? "eventInfoMore hiding active"
              : "eventInfoMore hiding"
          }
        >
          <p className="eventDesc text-large">
            {this.props.event.description}&emsp;
            {!this.props.past && !this.props.app.state.liveMode && (
              <a
                href={this.props.event.moreInfoURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                More info
              </a>
            )}
          </p>
          {!this.props.event.noProgram && (
            <>
              {this.showReadButton() && (
                <>
                  {this.props.app.state.customUser &&
                    this.props.app.state.customUser.library.includes(
                      this.props.event.id
                    ) && <h6 className="text-center">In Library</h6>}
                  <button
                    className="cta cta-lg cta-light button-icon read-program-btn"
                    onClick={this.clickRead}
                  >
                    Read Program
                  </button>
                </>
              )}
            </>
          )}

          {this.props.app.state.customUser && !this.props.past && (
            <>
              {this.props.bookmarked ? (
                <button
                  className="cta button-icon cta-lg cta-light bookmarked-btn"
                  onClick={(e) =>
                    this.props.app.clickBookmark(this.props.event)
                  }
                >
                  Bookmarked
                </button>
              ) : (
                <button
                  className="cta button-icon cta-lg cta-light bookmark-btn"
                  onClick={(e) =>
                    this.props.app.clickBookmark(this.props.event)
                  }
                >
                  Bookmark
                </button>
              )}
            </>
          )}
        </div>
        {this.props.expand ? (
          <button
            className="cta cta-light"
            onClick={(e) => this.clickExpand(e)}
          >
            Read Less
          </button>
        ) : (
          <button
            className="cta cta-light"
            onClick={(e) => this.clickExpand(e)}
          >
            Read More
          </button>
        )}
      </div>
    );
  }
}

export default withRouter(EventInfo);
