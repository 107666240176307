import React from 'react';
import FileInfo from './fileInfo';

function BrowserList(props) {

  var browserList = props.items.map((item, key) => {
    return (
      <li key={item.name}>
        <FileInfo 
          name={item.name} 
          path={item.path} 
          general={item.general}
          callback={props.callback} 
          storageRef={props.storageRef} />
          {props.admin && 
            <button 
              className="cta cta-small cta-light" 
              aria-label="Delete"
              onClick={(e) => props.deleteCallback(item.name, item.path)}>
              Delete
            </button>
          }
      </li>
    );
  })
  return (
    <ul>
      {browserList}
    </ul>
  );
}

export default BrowserList;