import React from 'react';
import { withRouter } from 'react-router-dom';
import SignUpStart from './signUpStart';
import SignUpDetails from './signUpDetails';
import SignUpFinish from './signUpFinish';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      birthday: null,
    };
  }
  handleError(err) {
    const errorCode = err.code || '';
    const message = err.message;
    console.log('ERROR', errorCode, message);
    this.setState({
      email: '',
      password: '',
    });
    this.props.app.setState({
      modalActive: true,
      modalData: {
        active: false,
        heading:  'A small problem',
        paragraph: message,
        cta: 'OK',
      }
    });
  }
  pushToHistory(e, path) {
    const history = this.props.history;
    history.push(path);
  }
  render() {
    return (
      <>
        {this.state.step === 0 && 
          <SignUpStart signUp={this} app={this.props.app} />
        }
        {this.state.step === 1 &&
          <SignUpDetails signUp={this} app={this.props.app} />
        }
        {this.state.step === 2 && 
          <SignUpFinish app={this.props.app}/>
        }
      </>
    );
  }
}

export default withRouter(SignUp);