import React from 'react';
import SponsorInfo from './sponsorInfo';

export default function SponsorsList(props) {
  var sponsorsList = props.sponsors.map((sponsor, key) => {
    return ( 
      <li key={sponsor.id}>
        <SponsorInfo sponsor={sponsor} manager={props.manager}/>
      </li> 
    );
  });

  return (
    <ul>
      {sponsorsList}
    </ul>
  );
}