import React from 'react';

class Toaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: '', 
      body: '', 
      points: '', 
      timeout: null,
    };
    this.clearToast = this.clearToast.bind(this);
    this.clickToast = this.clickToast.bind(this);
    this.getClassName = this.getClassName.bind(this);
  }
  loadToast() {
    var t = setTimeout(this.clearToast, 1000 * 4);
    this.setState({
      heading: this.props.toast.heading,
      body: this.props.toast.body, 
      points: this.props.toast.points, 
      timeout: t,
    });
  }
  clearToast() {
    this.props.manager.setToastActive(null);
  }
  clickToast() {
    this.clearToast();
    var t = this.state.timeout;
    clearTimeout(t);
    this.setState({
      timeout: null,
    });
  }
  getClassName() {
    var className = 'hiding';
    if (this.props.toast) {
      className += ' active ' + this.props.toast.className;
      if (this.props.manager.state.liveMode) {
        className += ' live';
      }
    }
    return className;
  }
  componentDidMount() {
    if (this.props.toast)
      this.loadToast();
  }
  componentDidUpdate(prevProps) {
    if (this.props.toast && prevProps.toast !== this.props.toast) {
      this.loadToast();
    }
  }
  render() {
    return (
      <>
        <div 
          id="toast" 
          className={this.getClassName()}
          onClick={this.clickToast}>
          {this.state.heading.length > 0 && 
            <h6>{this.state.heading}</h6>
          }
          <p className="text-center">
            {this.state.points ? 
              <span className="points-new">{this.state.body}</span>
              :
              <span>{this.state.body}</span>
            }
            {this.state.points &&
              <span>Your score is now <span className="points-total">{this.state.points}!</span></span>
            }
          </p>
        </div>
      </>
    );
  }
}

export default Toaster;