import React from 'react';
import firebase from '../firebase';
import BrowserList from './browserList';
import FileUpload from './fileUpload';
import GeneralMark from '../img/icons/general-mark.svg';

class FileBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storage: null,
      storageRef: null,
      pathRef: null,
      prefixes: [],
      items: [],
      fileUploadActive: false,
    };
    this.getList = this.getList.bind(this);
    this.getFileURL = this.getFileURL.bind(this);
    this.uploadCallback = this.uploadCallback.bind(this);
    this.clickUpload = this.clickUpload.bind(this);
    this.clickClose = this.clickClose.bind(this);
    this.clickDelete = this.clickDelete.bind(this);
  }
  getContextName() {
    var str;
    switch (this.props.context) {
      case 'eventWrite':
        str = `this event's`;
        break;
      case 'sponsorWrite':
        str = 'Partners';
        break;
      case 'programWrite':
        str = `this event's`;
        break;
      default: str = '';
    }
    return <span className="term">{str}</span>;
  }
  getFileURL(path) {
    const fileRef = this.state.storageRef.child(path);
    fileRef.getDownloadURL()
      .then((url) => {
        return url;
      });
  }
  getList(pathRef) {
    var prefixes = [];
    var items = [];
    pathRef.listAll()
    .then((res) => {
      // Make a simpler Items array
      res.items.forEach((i) => {
        const item = { 
          name: i.name, 
          path: pathRef.fullPath + '/' + i.name,
          general: false
        };
        items.push(item);
      });
      res.prefixes.forEach((p) => {
        prefixes.push(p);
      });
    })
    .then((res) => {
      const generalRef = firebase.storage().ref().child('general');
      generalRef.listAll()
      .then((res) => {
        res.items.forEach((i) => {
          const item = {
            name: i.name,
            path: generalRef.fullPath + '/' + i.name,
            general: true
          };
          items.push(item);
        });
        res.prefixes.forEach((p) => {
          prefixes.push(p);
        });
      })
      .then((res) => {
        this.setState({
          prefixes: prefixes, 
          items: items, 
        });
      });
    })
    .catch((err) => {
      console.log('Error while getting file list',err.code, err.message);
    });
  }
  clickUpload(e) {
    this.setState({
      fileUploadActive: true,
    });
  }
  clickDelete(e, itemName, itemPath) {
    var delRef = this.state.storageRef.child(itemName);
    // Delete the file
    delRef.delete().then(() => {
      this.getList(this.state.storageRef);
      this.clickClose({});
    }).catch((error) => {
      console.log('error during deletion', error);
    });
  }
  uploadCallback(snapshot) {
    const pathRef = this.state.storageRef.child(this.props.path);
    this.getList(pathRef);
    this.setState({
      pathRef,
      fileUploadActive: false,
    });
  }
  clickClose(e) {
    this.props.app.setState({
      fileBrowserActive: false,
    });
  }
  componentDidMount() {
    window.addEventListener('popstate', (e) => {
      this.props.app.setState({
        fileBrowserActive: false
      });
    });
    const storage = firebase.storage();
    const storageRef = storage.ref();
    /*const pathRef = storageRef.child(this.props.path);
    this.getList(pathRef);*/
    this.setState({
      storage, 
      storageRef, 
      //pathRef, 
    });
    if (this.props.path) {
      const pathRef = storageRef.child(this.props.path);
      this.getList(pathRef);
      this.setState({
        pathRef
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (prevProps.path !== this.props.path) {
        if (this.props.path) {
          const pathRef = this.state.storageRef.child(this.props.path);
          this.getList(pathRef);
          this.setState({
            pathRef,
          });
        }
      }
      else {
        if (this.props.path) {
          if (this.state.pathRef) {
            this.getList(this.state.pathRef);
          }
        }
      }
    }
  }
  render() {
    return (
      <>
        {this.state.fileUploadActive ? 
          <FileUpload 
            app={this.props.app}
            path={this.props.path}
            context={this.props.context} 
            manager={this} 
            firebase={this.props.firebase} 
            callback={this.uploadCallback} />
          :
          <div 
            id="file-browser" 
            className={this.props.app.state.fileBrowserActive ? 'text-center hiding active' : 'text-center hiding'}>
            {this.props.context !== 'userWrite' ? 
              <div>
                <h2>Choose a File</h2>
                <p>You are viewing files uploaded to {this.getContextName()} collection and the <img src={GeneralMark} className="general-mark" alt="G icon"></img><span className="term">General</span> collection.</p>
              </div>
              :
              <div>
                <h2>You can upload one profile pic at a time.</h2>
                <p>Make sure it's no larger than <span className="term">7 MB</span>!</p>
              </div>
            }
            {this.state.items.length > 0 && 
              <BrowserList 
                admin={this.props.app.state.customUser.admin} 
                items={this.state.items} 
                callback={this.props.callback} 
                storageRef={this.state.storageRef} 
                deleteCallback={this.clickDelete} />
            }
            {this.props.context === 'userWrite' && 
              <p>Click the pic to confirm, or upload something new.</p>
            }
            <button className="cta cta-black cta-lg" onClick={this.clickUpload}>Upload a new file</button>
            <button className="cta cta-trans" onClick={this.clickClose}>Close File Browser</button>
          </div>
        }
      </>
    );
  }
}

export default FileBrowser;