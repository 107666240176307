import React from 'react';
import ASLIcon from '../img/asl.jpg';
import AudioIcon from '../img/audio-description.jpg';
import CaptioningIcon from '../img/open-captioning.jpg';
import SensoryFriendlyIcon from '../img/sensory-friendly.jpg';

function OfferingsRow(props) {
  var offeringsList = props.offerings.map((o) => {
    if (o.name === 'asl')
      return <li key={o.name}><img src={ASLIcon} alt="ASL Interpretation icon" /></li>
    else if (o.name === 'audiodescription')
      return <li key={o.name}><img src={AudioIcon} alt="Audio Description icon" /></li>
    else if (o.name === 'opencaptioning')
      return <li key={o.name}><img src={CaptioningIcon} alt="Open Captioning icon" /></li>
    else if (o.name === 'sensoryfriendly')
      return <li key={o.name}><img src={SensoryFriendlyIcon} alt="Sensory Friendly icon" /></li>
    else return null;
  });
  return (
    <ul className="offerings-row flex-row">
      {offeringsList}
    </ul>
  )
}

export default OfferingsRow;