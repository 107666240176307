import React from 'react';
import { withRouter } from 'react-router-dom';

class Toolbar extends React.Component {
  constructor(props) {
    super(props);
    this.pushToHistory = this.pushToHistory.bind(this);
  }
  pushToHistory(path) {
    if (!this.props.app.state.fileBrowserActive) {
      var allowed = true;
      // If reading program
      if (this.props.location.pathname.includes('program')) {
        // And we have a customUser and a featured event
        if (this.props.customUser && this.props.app.state.featuredEvent && 
          // And it's live mode
          this.props.app.state.liveMode && 
          // And the customUser is not an admin
          //this.props.customUser.admin === false && 
          // And the user checked in at the featured event
          this.props.customUser.attendance.includes(this.props.app.state.featuredEvent.id)) {
          // And user is trying to leave program
          if (path ==='/' || path === '/events' || path === '/profile') {
            // Stop them
            allowed = false;
          }
        }
      }
      if (allowed)
        this.props.history.push(path);
      else {
        const toast = this.props.app.makeNewToast('Please do not browse the app during showtime.');
        this.props.app.pushToast([toast]);
      }
    }
  }
  render() {
    return (
      <header id="mainHeader" className={this.props.liveMode ? 'live' : ''}>
        <div className="row-container">
          <button 
            id="mhLogo" 
            onClick={(e) => this.pushToHistory('/')}>
            <h1>
              <span id="logoArts">Arts</span>&nbsp; 
              <span id="logoMU">MU</span>&nbsp; 
              <span id="logoLive">LIVE!</span>
            </h1> 
          </button>
          <button id="mhSched"
            onClick={(e) => this.pushToHistory('/events')}>
            <span className="text-hidden">Schedule</span>
          </button>
          {this.props.logInSpinnerActive ? 
            <div className="spinner spinner-sm"></div>
            :
            <div id="mhProfile">
              {this.props.customUser ? 
                <button
                  id="mhProfPic"
                  onClick={(e) => this.pushToHistory('/profile')}>
                  {this.props.customUser.photoURL ? 
                    <picture className="picture-round profile-pic">
                      <img width="40" alt={this.props.customUser.displayName} src={this.props.customUser.photoURL} />
                    </picture>
                    :
                    <span>{this.props.customUser.displayName}</span>
                  }
                  
                </button>
                :
                <button 
                  id="logIn" 
                  className="cta"
                  onClick={(e) => this.pushToHistory('/login')}>
                  Log In
                </button>
              }
            </div>
          }
        </div>
      </header>
    );
  }
}

export default withRouter(Toolbar);