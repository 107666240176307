import React from 'react';
import BadgesList from './badgesList.js';

class BadgeReport extends React.Component {
	constructor(props) {
		super(props);
		this.clickClose = this.clickClose.bind(this);
	}
	getBadgeName(id) {
		const names = [
			'Tester',
			'Birthday 2020',
			'Welcome',
			'First Show',
			'Three Shows',
			'Five Shows',
			'Ten Shows',
			'Top Five',
			'Patron of the Arts',
			'First Bookmark',
			'Piqued Interest',
			'In the Loop',
			'Welcome to Live Mode',
			'Live Mode Expert',
			'Live Mode Veteran',
			'Matinée',
		];
		return names[id];
	}
	getSharingText() {
		var prefix = `I just got the`;
		var main = '';
		if (this.props.app.state.customUser.badgeQueue.length === 1) {
			const b = this.props.app.state.customUser.badgeQueue[0];
			main = ` ${this.getBadgeName(b.id)} badge!`
		}
		else if (this.props.app.state.customUser.badgeQueue.length > 1) {
			main = 'se badges:';
			this.props.app.state.customUser.badgeQueue.forEach((b) => {
				main += ` ${this.getBadgeName(b.id)}!`
			});
		}
		var suffix = ` #artsmulive Compete with me at artsmulive.com!`;
		const escaped = encodeURIComponent(prefix + main + suffix);
		return escaped;
	}
	updateQueue() {
		var customUser = this.props.app.state.customUser;
		customUser.badgeQueue = [];
		this.props.app.updateUserData(customUser);
	}
	clickClose() {
		window.scrollTo(0, 0);
		this.updateQueue();
		this.props.app.setState({
			badgeReportActive: false,
		});
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div className="badge-report promo">
				<h1>Congratulations</h1>
				{this.props.app.state.customUser && this.props.app.state.customUser.badgeQueue.length === 1 ? 
					<h2 className="text-center">You got a new Badge!</h2>
					:
					<h2 className="text-center">You got new Badges!</h2> 
				}
				{this.props.app.state.customUser && 
					<>
						<BadgesList 
							badges={this.props.app.state.customUser.badgeQueue} 
							description />
						{this.props.app.state.customUser.badgeQueue.length === 1 ? 
							<p className="text-center"><span className="points-new">+25 Points!</span> Your score is now <span className="points-total">{this.props.app.state.customUser.points}!</span></p>
							:
							<p className="text-center"><span className="points-new">+{(this.props.app.state.customUser.badgeQueue.length * 25)} Points!</span> Your score is now <span className="points-total">{this.props.app.state.customUser.points}!</span></p>
						}
						<div className="admin-panel">
							<h2>Share</h2>
							<ul id="shareButtons">
								<li id="twitter">
									<a 
										className="twitter-share" 
										target="_blank" 
										rel="noopener noreferrer" 
										href={`https://twitter.com/intent/tweet?text=${this.getSharingText()}`} 
										data-size="large">
										Twitter
									</a>
								</li>
								<li id="facebook">
									<div 
										className="fb-share" 
										data-href="https://artsmulive.com" 
										data-layout="button" 
										data-size="large">
										<a 
											target="_blank" 
											rel="noopener noreferrer" 
											href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fartsmulive.com&amp;src=sdkpreparse" 
											className="fb-xfbml-parse-ignore">
											Facebook
										</a>
									</div>
								</li>
							</ul>
							<h3 className="text-center">#artsmulive</h3>
						</div>
						<button className="cta cta-lg cta-light" onClick={this.clickClose}>Close</button>
					</>
				}
			</div>
		);
	}
}

export default BadgeReport;