import React from 'react';
import { withRouter } from 'react-router-dom';

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
    this.pushToHistory = this.pushToHistory.bind(this);
  }
  pushToHistory(e, path) {
    const history = this.props.history;
    history.push(path);
  }
  handleError(err) {
    const errorCode = err.code;
    const message = err.message;
    console.log('ERROR', errorCode, message);
    this.setState({
      email: '',
      password: '',
    });
    this.props.app.setState({
      modalActive: true,
      modalData: {
        active: false,
        heading: 'A small problem',
        paragraph: message,
        cta: this.props.app.state.modalData.cta,
      }
    });
  }
  handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}
	handleSubmit(event) {
    event.preventDefault();
    this.props.app.setState({
      logInSpinnerActive: true,
    });
		this.props.app.logIn(this.state.email, this.state.password, this);
		this.setState({
			email: '', 
			password: '', 
    });
	}
  render() {
    return (
      <div className="standard-page">
        <div
          className="section-skewed promo text-center">
          <h1>Welcome back!</h1>
          <h2>Log in with your email and password.</h2>
          <div className="signInPanel">
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <label>
                Email
                <input 
                  required 
                  id="email" 
                  className="input-light"
                  name="email" 
                  type="email" 
                  placeholder="your@email.address" 
                  value={this.state.email} 
                  onChange={(e)=>this.handleChange(e)} />
              </label>
              <label>
                Password
                <input
                  required 
                  id="password" 
                  className="input-light"
                  name="password" 
                  type="password" 
                  placeholder="your password" 
                  value={this.state.password} 
                  onChange={(e)=>this.handleChange(e)} />
              </label>
              <p>
                <button 
                  className="cta-underline" 
                  type="button" 
                  onClick={(e) => this.pushToHistory(e, '/resetpassword')}>
                  I Forgot My Password
                </button>
              </p>
              <input 
                id="submit" 
                className="input-light"
                name="submit" 
                type="submit" 
                value="Log In" />
            </form>
          </div>
        </div>
        <h2>Don&#39;t have an account?</h2>
        <p>Quickly create a free <span className="term">Profile</span> to collect <span className="term">Programs</span> and plan your next visit.</p>
        <button
          className="cta cta-lg"
          onClick={(e) => this.pushToHistory(e, '/signup')}>
          Sign Up
        </button>
      </div>
    );
  }
}

export default withRouter(LogIn);