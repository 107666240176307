import React from 'react';
import { withRouter } from 'react-router-dom';

function Breadcrumbs(props) {
  var click = (e, path) => {
    if (props.onClick)
      props.onClick();
    else
      pushToHistory(path);
  }
  var pushToHistory = (path) => {
    props.history.push(path);
  }
  
  return (
    <nav id="breadcrumbs">
			<ul>
				<li>
					<button onClick={(e) => click(e, props.path)}>{props.innerHTML}</button>
				</li>
			</ul>
		</nav>
  );
}

export default withRouter(Breadcrumbs);