import React from 'react';
import Breadcrumbs from './breadcrumbs';
import UserRowList from './userRowList';

class UsersAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customUsers: []
    };
  }
  componentDidMount() {
    this.props.app.readAllUsersData();
  }
  render() {
    return (
      <div id="users-all">
        <Breadcrumbs path="/profile" innerHTML="Profile" />
        <h1>All Users</h1>
        {this.props.app.state.allCustomUsers &&
          <div>
            <header>
              <p>User count <strong>{this.props.app.state.allCustomUsers.length}</strong></p>
            </header>
            <div id="users-all-inner">
              <UserRowList 
                app={this.props.app} 
                users={this.props.app.state.allCustomUsers} />
            </div>
          </div>
        }
      </div>
    );
  }
}

export default UsersAll;