import React from "react";
import firebase from "../firebase";
import moment from "moment";
import { Route, Switch, withRouter } from "react-router-dom";
import Breadcrumbs from "./breadcrumbs";
import EventsList from "./eventsList";
import EventWrite from "./eventWrite";
import ProgramWrite from "./programWrite";
import EventModal from "./eventModal";

class ScheduleWrite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventWriteActive: false,
      eventToWrite: {},
      eventModalActive: false,
      eventToModal: {},
    };
  }
  // Create
  postNewEvent(event, eventRef) {
    // set event & program in database
    eventRef.set(event);
    const pRef = firebase.database().ref("programs/" + event.program.id);
    pRef.set(event.program);
    // add this event into the schedule and then sort
    var schedule = [...this.props.app.state.schedule];
    schedule.push(event);
    schedule.sort((a, b) => {
      // Compare function
      var ma = moment(a.startTime).valueOf();
      var mb = moment(b.startTime).valueOf();
      return ma - mb;
    });
    // add this event's program to state
    var programs = [...this.props.app.state.programs];
    programs.push(event.program);
    this.props.app.setState({
      schedule,
      programs,
    });
    this.setState({
      eventWriteActive: true,
      eventToWrite: event,
    });
  }
  // Update
  updateEventInDatabase(event, eventRef) {
    eventRef.set(event);
  }

  // Event factory for new Events
  makeNewEvent(
    id,
    title,
    locationMain,
    locationDetail,
    startTime,
    endTime,
    genre,
    description,
    coverPhotoURL,
    accessibility,
    program,
    path,
    moreInfoURL,
    tags,
    published,
    useGeolocation = -1,
	programAvailable = true
  ) {
    const event = {
      id,
      title,
      locationMain,
      locationDetail,
      startTime,
      endTime,
      genre,
      description,
      coverPhotoURL,
      accessibility,
      program,
      path,
      moreInfoURL,
      tags,
      published,
      useGeolocation,
	  programAvailable
    };
    return event;
  }
  // Button callback for New Event button
  clickNewEvent(e) {
    this.addBlankEvent();
    //this.props.app.readAllEventData();
  }
  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
  addBlankEvent() {
    const now = moment().format();
    const event = this.makeNewEvent(
      now,
      "New Event",
      "",
      "",
      now,
      now,
      "",
      "",
      "",
      [],
      this.makeNewProgram(now, now),
      "",
      "",
      [],
      false,
	  -1,
	  false,
    );
    const eRef = firebase.database().ref("schedule/" + event.id);
    this.postNewEvent(event, eRef);
  }
  makeNewProgram(eventID, now) {
    var newProgram = {
      id: eventID,
      path: "NewEvent",
      elements: [
        {
          id: moment().subtract(5, "second").format(),
          eType: "headingvertical",
          innerHTML: "Program",
          url: "",
          alt: "",
          caption: "",
        },
        {
          id: moment().subtract(4, "second").format(),
          eType: "headinglarge",
          innerHTML: "Performer Name",
          url: "",
          alt: "",
          caption: "",
        },
        {
          id: moment().subtract(3, "second").format(),
          eType: "body",
          innerHTML: "performer role",
          url: "",
          alt: "",
          caption: "",
        },
        {
          id: moment().subtract(2, "second").format(),
          eType: "headinglarge",
          innerHTML: "Performer Name",
          url: "",
          alt: "",
          caption: "",
        },
        {
          id: moment().subtract(1, "second").format(),
          eType: "body",
          innerHTML: "performer role",
          url: "",
          alt: "",
          caption: "",
        },
      ],
    };
    console.log("new program", newProgram);
    return newProgram;
  }
  setModalActive(val) {
    this.setState({
      eventModalActive: val,
    });
    
  }
  componentDidMount() {
    if (this.props.schedule.length < 1) this.props.app.readAllEventData();
    if (this.props.app.state.tags.length < 1) this.props.app.readAllTagData();
  }
  render() {
    return (
      <Switch>
        <Route path="/dashboard/events/:eventPath/program">
          <ProgramWrite
            app={this.props.app}
            programs={this.props.app.state.programs}
          />
        </Route>

        <Route path="/dashboard/events/:eventPath?">
          {this.state.eventWriteActive ? (
            <EventWrite
              app={this.props.app}
              manager={this}
              eventToWrite={this.state.eventToWrite}
              tagOptions={this.props.app.state.tags}
            />
          ) : (
            <>
              <Breadcrumbs path={"/profile"} innerHTML={"Profile"} />
              <div>
                <div id="addEventSection" className="bg-gold">
                  <p className="text-center">
                    Viewing <span className="term">Schedule</span> as{" "}
                    <span className="term">Admin</span>.
                  </p>
                  <button
                    className="cta cta-light"
                    onClick={(e) => this.clickNewEvent(e)}
                  >
                    Add Event
                  </button>
                </div>
                {this.props.schedule && this.props.schedule.length > 0 ? (
                  <EventsList
                    manager={this}
                    schedule={this.props.schedule}
                    write
                  />
                ) : (
                  <p className="text-center">
                    There are no upcoming events scheduled at this time.
                  </p>
                )}
              </div>
              <EventModal
                active={this.state.eventModalActive}
                manager={this}
                schedule={this.props.schedule}
                app={this.props.app}
              />
            </>
          )}
        </Route>
      </Switch>
    );
  }
}

export default withRouter(ScheduleWrite);
