import React from 'react';
import firebase from '../firebase';
import Breadcrumbs from './breadcrumbs';
import SponsorsList from './sponsorsList';
import SponsorWrite from './sponsorWrite';

class Sponsors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsorWriteActive: false,
      sponsorToWrite: {},
    };
  }
  // Sponsor C.R.U.D.
  // Create
  postNewSponsor(sponsor, sRef) {
    // set sponsor in database
    sRef.set(sponsor);
    this.setState({
      sponsorWriteActive: true,
      sponsorToWrite: sponsor,
    });
  }

  clickAddSponsor(e) {
    this.addBlankSponsor();
    this.props.app.readAllSponsorData();
  }
  makeNewSponsor(id, name, level, sponsorURL, imageURL, noLogo) {
    const sponsor = {
      id: id,
      name: name,
      level: level, 
      sponsorURL: sponsorURL, 
      imageURL: imageURL, 
      noLogo: noLogo, 
    };
    return sponsor;
  }
  addBlankSponsor() {
    const sponsor = this.makeNewSponsor(new Date().toString(), 'New Partner', 'platinum', '', '', false);
    const sRef = firebase.database().ref('sponsors/' + sponsor.id);
    this.postNewSponsor(sponsor, sRef);
  }
  // Update
  updateSponsorInDatabase(sponsor, sRef) {
    sRef.set(sponsor);
  }
  componentDidMount() {
    if (this.props.sponsors.length < 1)
      this.props.app.readAllSponsorData();
  }
  render() {
    return (
      <div className={this.props.app.state.fileBrowserActive ? 'standard-page hiding' : 'standard-page hiding active'}>
        {this.state.sponsorWriteActive ? 
          <SponsorWrite 
            app={this.props.app}
            manager={this} 
            active={this.state.sponsorWriteActive} 
            sponsorToWrite={this.state.sponsorToWrite} 
            firebase={firebase}/>
          :
          <>
            <Breadcrumbs path={'/profile'} innerHTML={'Profile'} />
            <h1>Partners</h1>
            {this.props.app.state.sponsors && 
              <SponsorsList manager={this} sponsors={this.props.app.state.sponsors} />
            }
            <button 
              className="cta"
              onClick={(e) => this.clickAddSponsor(e)}>
              Add Sponsor
            </button>
          </>
        }
      </div>
    );
  }
}

export default Sponsors;