import React from 'react';

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enjoyment: '',
      return: '',
      recommend: '',
      improvement: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    const results = {
      enjoyment: this.state.enjoyment,
      likelyToUseAgain: this.state.return,
      wouldRecommend: this.state.recommend,
      improvement: this.state.improvement
    };
    this.props.app.submitFeedback(results);
  }
  render() {
    return (
      <section id="feedback">
        <form onSubmit={this.handleSubmit}>
          <section>
            <p>How did you enjoy using <span className="term">Arts MU Live!</span> today?</p>
            <div>
              <label>
                <input 
                  id="enjoyment1" 
                  type="radio" 
                  name="enjoyment" 
                  value="1 Completely Disliked" 
                  onChange={this.handleChange}
                  required />
                <span>Completely Disliked</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="enjoyment2" 
                  type="radio" 
                  name="enjoyment" 
                  value="2 Somewhat Disliked" 
                  onChange={this.handleChange} />
                <span>Somewhat Disliked</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="enjoyment3" 
                  type="radio" 
                  name="enjoyment" 
                  value="3 Neutral" 
                  onChange={this.handleChange} />
                <span>Neutral</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="enjoyment4" 
                  type="radio" 
                  name="enjoyment" 
                  value="4 Enjoyed" 
                  onChange={this.handleChange} />
                <span>Enjoyed</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="enjoyment5" 
                  type="radio" 
                  name="enjoyment" 
                  value="5 Greatly Enjoyed" 
                  onChange={this.handleChange} />
                <span>Greatly Enjoyed</span>
              </label>
            </div>
          </section>
          <section>
            <p>How likely are you to use this environmentally friendly app in the future?</p>
            <div>
              <label>
                <input 
                  id="return1" 
                  type="radio" 
                  name="return" 
                  value="1 Very Unlikely" 
                  onChange={this.handleChange} 
                  required />
                <span>Very Unlikely</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="return2" 
                  type="radio" 
                  name="return" 
                  value="2 Unlikely" 
                  onChange={this.handleChange} />
                <span>Unlikely</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="return3" 
                  type="radio" 
                  name="return" 
                  value="3 Neutral" 
                  onChange={this.handleChange} />
                <span>Neutral</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="return4" 
                  type="radio" 
                  name="return" 
                  value="4 Likely" 
                  onChange={this.handleChange} />
                <span>Likely</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="return5" 
                  type="radio" 
                  name="return" 
                  value="5 Very Likely" 
                  onChange={this.handleChange} />
                <span>Very Likely</span>
              </label>
            </div>
          </section>
          <section>
            <p>How likely are you to recommend this app to patrons of cultural events presented by <span className="term">The Arts at MU</span>?</p>
            <div>
              <label>
                <input 
                  id="recommend1" 
                  type="radio" 
                  name="recommend" 
                  value="1 Wouldn't Recommend" 
                  onChange={this.handleChange} 
                  required />
                <span>Wouldn't Recommend</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="recommend2" 
                  type="radio" 
                  name="recommend" 
                  value="2 Might Not Recommend" 
                  onChange={this.handleChange} />
                <span>Might Not Recommend</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="recommend3" 
                  type="radio" 
                  name="recommend" 
                  value="3 Neutral" 
                  onChange={this.handleChange} />
                <span>Neutral</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="recommend4" 
                  type="radio" 
                  name="recommend" 
                  value="4 Likely to Recommend" 
                  onChange={this.handleChange} />
                <span>Likely to Recommend</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="recommend5" 
                  type="radio" 
                  name="recommend" 
                  value="5 Definitely Recommend" 
                  onChange={this.handleChange} />
                <span>Definitely Recommend</span>
              </label>
            </div>
          </section>
          <section>
            <p>What improvements would you most like to see implemented in this app next season?</p>
            <div>
              <label>
                <input 
                  id="improvement1" 
                  type="radio" 
                  name="improvement" 
                  value="1 Better usability" 
                  onChange={this.handleChange} 
                  required />
                <span>Refine & Improve Usability</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="improvement2" 
                  type="radio" 
                  name="improvement" 
                  value="2 More variety inside programs" 
                  onChange={this.handleChange} />
                <span>More Variety in Program Layout</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="improvement3" 
                  type="radio" 
                  name="improvement" 
                  value="3 trivia game" 
                  onChange={this.handleChange} />
                <span>Play Trivia Game for Points</span>
              </label>
            </div>
            <div>
              <label>
                <input 
                  id="improvement4" 
                  type="radio" 
                  name="improvement" 
                  value="4 Profile customization" 
                  onChange={this.handleChange} />
                <span>More Profile Customization</span>
              </label>
            </div>
          </section>
          
          <input type="submit" value="Submit Feedback" />
        </form>
      </section>
    );
  }
}

export default Feedback;