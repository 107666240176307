import React from 'react';
import LibraryCover from './libraryCover';

function LibraryList(props) {
  const getEventFromID = (id) => {
    const e = props.app.state.schedule.find((event) => {
      return event.id === id;
    });
    return e;
  }
  var libraryList = props.library.map((id, key) => {
    return (
      <li key={id}>
        <LibraryCover 
          className="library-cover"
          app={props.app}
          event={getEventFromID(id)} />
      </li>
    );
  });
  return (
    <ul id="libraryList" data-child-count={props.library.length}>
      {libraryList}
    </ul>
  );
}

export default LibraryList;