import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ProgramRead from './programRead';
import EventsList from './eventsList';

class ScheduleRead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedID: '',
    };
  }
  componentDidMount() {
    if (this.props.schedule.length < 1)
      this.props.app.readAllEventData();
      // Grab route params
    const { match: { params } } = this.props;
    this.setState({
      expandedID: params.eventTitle,
    });
  }
  render() {
    return (
      <Switch>
        <Route path="/events/:eventPath/program">
          <ProgramRead app={this.props.app} programs={this.props.programs} />
        </Route>
        <Route path="/events/:eventPath?">
          <section id="sectionSchedule">
            {this.props.schedule && this.props.schedule.length > 0 && 
              <EventsList 
                manager={this} 
                schedule={this.props.schedule}
                featuredEvent={this.props.app.state.featuredEvent} />
            }
          </section>
        </Route>
      </Switch>
    );
  }
}

export default withRouter(ScheduleRead);