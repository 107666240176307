import React from 'react';

class Modal extends React.Component {
  finish() {
    this.props.manager.setState({
      modalActive: false,
      modalData: {
        heading: '',
        paragraph: ''
      }
    });
  }
  clickCTA(e) {
    // Maybe add logic to handle YES/NO question
    this.finish();
  }
  render() {
    return (
      <div className={this.props.manager.state.modalActive ? 'modal hiding active' : 'modal hiding'}>
        <div className="modal-inner text-center">
          <h2>{this.props.manager.state.modalData.heading}</h2>
          <p>{this.props.manager.state.modalData.paragraph}</p>
          <button className="cta" onClick={(e) => this.clickCTA(e)}>{this.props.manager.state.modalData.cta}</button>
        </div>
      </div>
    );
  }
}

export default Modal;