import React from 'react';
import firebase from '../firebase';
import LeaderboardIcon from '../img/icons/leaderboard.png';

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leaderboard: [],
      slot1: [],
      slot2: [],
      slot3: [],
    };
  }
  checkTopThree(leaderboard) {
    const slot1 = this.getUsersWithScore(leaderboard, leaderboard[0].points, 0);
    if (slot1.match.length > 0 && leaderboard[slot1.lastIndex] !== undefined) {
      this.grabSlot(slot1, 1);
      console.log('slot1 info', leaderboard[slot1]);
      const slot2 = this.getUsersWithScore(leaderboard, leaderboard[slot1.lastIndex].points, slot1.lastIndex);
      if (slot2.match.length > 0 && leaderboard[slot2.lastIndex] !== undefined) {
        this.grabSlot(slot2, 2);
        const slot3 = this.getUsersWithScore(leaderboard, leaderboard[slot2.lastIndex].points, slot2.lastIndex);
        if (slot3.match.length > 0)
          this.grabSlot(slot3, 3);
      }
      
    }
      
  }
  grabSlot(slot, slotNumber) {
    var slotIDs = [];
    slot.match.forEach((u) => {
      slotIDs.push(u.uid);
    });
    this.setState({
      ['slot' + slotNumber]: slotIDs
    });
  }
  getUsersWithScore(leaderboard, score, startIndex) {
    var match = [];
    var lastIndex = -1;
    for (let u = startIndex; u < leaderboard.length; u++) {
      if (leaderboard[u].points === score) {
        match.push(leaderboard[u]);
      }
      else if (lastIndex < 0) {
        lastIndex = u;
      }
    }
    return { match, lastIndex };
  }
  componentDidMount() {
    const ref = firebase.database().ref('/leaderboard/');
    ref.once('value', (snapshot) => {
      const rawBoard = snapshot.val();
      var leaderboard = [];
      rawBoard.forEach((u) => {
        const customUser = this.props.app.rebuildCustomUser(u);
        leaderboard.push(customUser);
      })
      this.checkTopThree(leaderboard);
      this.setState({
        leaderboard
      });
    });
  }
  getDataSlot(customUser) {
    if (this.state.slot1.includes(customUser.uid))
      return 1;
    if (this.state.slot2.includes(customUser.uid))
      return 2;
    if (this.state.slot3.includes(customUser.uid))
      return 3;
    else return -1;
  }
  render() {
    var getLeaderboard = this.state.leaderboard.map((u, key) => {
      return (
        <li key={key} data-slot={this.getDataSlot(u)}>
          <picture className="picture-round profile-pic" data-tier={u.tier}>
            <img height="32" width="32" alt="" src={u.photoURL} />
          </picture>
          <p>{u.displayName}</p>
          <p>{u.points || 0}</p>
        </li>
      );
    });
    return (
      <div id="leaderboard" className="standard-page">
        <div className="promo">
          <img width="64" src={LeaderboardIcon} alt="Leaderboard icon"/>
          <h1>Leaderboard</h1>
          <p>Earn <span className="term">Points</span> to raise your <span className="term">Score</span> and climb up the <span className="term">Leaderboard</span>! You can earn <span>Points</span> by using this app: <span className="term">Check In</span> at shows to get a big boost, and try out other features for smaller bonuses.</p>
          {this.state.leaderboard.length > 0 &&
            <>
              <ul id="leaderboard" className="leaderboard">
                {getLeaderboard}
              </ul>
            </>
          }
        </div>
      </div>
    );
  }
}

export default Leaderboard;