import React from 'react';
import UserRow from './userRow';

function UserRowList(props) {
  var userRowList = props.users.map((u) => {
    return (
      <tr key={u.uid}>
        <UserRow app={props.app} customUser={u} />
      </tr>
    );
  });
  return (
    <>
      {props.users.length > 0 && 
        <table>
          <thead>
            <tr><UserRow app={props.app} labels /></tr>
          </thead>
          <tbody>
            {userRowList}
          </tbody>
        </table>
      }
    </>
  );
}

export default UserRowList;