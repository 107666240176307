import React from 'react';

function TierDisplay(props) {
	const tiers = [
		'Bronze',
		'Silver',
		'Gold',
	];
	var tierDisplay = () => {
		return tiers[props.tier];
	}
	var tierImg = () => {
		return <span></span>
	}
	return (
		<span className="tier" data-tier={props.tier}>
			{tierImg()}
			{tierDisplay()}
		</span>
	)
}

export default TierDisplay;