import React from 'react';
import moment from 'moment';

function UserRow(props) {
  var getLastLogIn = () => {
    if (props.customUser.lastLogIn)
      return moment(props.customUser.lastLogIn).format('M/D/YY @ h:mm A')
    else
      return null
  }
  var getBirthDate = () => {
    if (props.customUser.birthday)
      return moment(props.customUser.birthday).format('M/D/YY');
    else
      return null;
  }
  var getDeleteDate = () => {
    if (!props.customUser.deleted)
      return 'Active'
    else 
      return 'Deleted ' + moment(props.customUser.deleted).format('M/D/YY @ h:mm A')
  }
  var getFeedback = () => {
    if (props.customUser.feedback === null) 
      return "Not given"
    else {
      const f = props.customUser.feedback;
      return `${f.enjoyment}/${f.likelyToUseAgain}/${f.wouldRecommend}/${f.improvement}`;
    }
  }
  return (
    <>
      {props.labels ? 
        <>
          <th>
            <span style={{display: 'block', height: '1rem', width: '1rem', borderRadius: '.5rem'}}></span>
          </th>
          <th>Display Name</th>
          <th>Email</th>
          <th>Last Log In</th>
          <th>Full Name</th>
          <th>Birth Date</th>
          <th>Bookmarks</th>
          <th>Attendance</th>
          <th>Score</th>
          <th>Badges</th>
          <th>Feedback</th>
          <th>Status</th>
        </>
        :
        <>
          <td style={{padding: '0'}}>
            {props.customUser.photoURL ? 
              <img className="hover-scale" height="1.5" width="1.5" style={{display: 'block', height: '1.5rem', width: '1.5rem', overflow: 'hidden'}} src={props.customUser.photoURL} alt="-" />
              :
              null
            }
          </td>
          <td>
            {props.customUser.displayName}
            {props.customUser.admin && 
              <span className="admin-mark">Admin</span>
            }
          </td>
          <td>{props.customUser.email}</td>
          <td>{getLastLogIn()}</td>
          <td>{props.customUser.fullName}</td>
          <td>{getBirthDate()}</td>
          <td>{props.customUser.bookmarks.length}</td>
          <td>
            {props.customUser.attendance && props.customUser.attendance.length > 0 ? 
              <span>{props.customUser.attendance.length}</span>
              :
              null
            }
          </td>
          <td>
            {props.customUser.points ? 
              <span>{props.customUser.points}</span>
              :
              <span>0</span>
            }
          </td>
          <td>
            {props.customUser.badges && props.customUser.badges.length > 0 ? 
              <span>{props.customUser.badges.length}</span>
              :
              <span>0</span>
            }
          </td>
          <td><span>{getFeedback()}</span></td>
          <td>
            {props.customUser.deleted === null ? 
              <span>Active</span>
              :
              <span>{getDeleteDate()}</span>
            }
          </td>
        </>
      }
    </>
  )
}

export default UserRow;