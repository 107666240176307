import React from 'react';
import moment from 'moment';
import Test from '../img/badges/test.png';
import TestEmb from '../img/badges/test-embossed.png';
import Birthday from '../img/badges/birthday.png';
import BirthdayEmb from '../img/badges/birthday-embossed.png';
import Bookmarks1 from '../img/badges/bookmarks-1.png';
import Bookmarks1Emb from '../img/badges/bookmarks-1-embossed.png';
import Bookmarks5 from '../img/badges/bookmarks-5.png';
import Bookmarks5Emb from '../img/badges/bookmarks-5-embossed.png';
import Bookmarks10 from '../img/badges/bookmarks-10.png';
import Bookmarks10Emb from '../img/badges/bookmarks-10-embossed.png';
import FirstShow from '../img/badges/first-show.png';
import FirstShowEmb from '../img/badges/first-show-embossed.png';
import ThirdShow from '../img/badges/third-show.png';
import ThirdShowEmb from '../img/badges/third-show-embossed.png';
import FifthShow from '../img/badges/fifth-show.png';
import FifthShowEmb from '../img/badges/fifth-show-embossed.png';
import TopBoard from '../img/badges/leaderboard-1.png';
import TopBoardEmb from '../img/badges/leaderboard-1-embossed.png';
import Live1 from '../img/badges/live-mode-1.png';
import Live1Emb from '../img/badges/live-mode-1-embossed.png';
import Live5 from '../img/badges/live-mode-5.png';
import Live5Emb from '../img/badges/live-mode-5-embossed.png';
import Live10 from '../img/badges/live-mode-10.png';
import Live10Emb from '../img/badges/live-mode-10.png';
import Matinee from '../img/badges/matinee.png';
import MatineeEmb from '../img/badges/matinee-embossed.png';
/*import Fall2020 from '../img/badges/seasonal-2020-fall.png';
import Summer2020 from '../img/badges/seasonal-2020-fall.png';
import Winter2020 from '../img/badges/seasonal-2020-fall.png';
more seasons
*/


function BadgeDisplay(props) {
	// eslint-disable-next-line
	const ids = [
		'test',				// 0
		'birthday',
		'welcome',		// 2
		'firstshow',
		'threeshows',	// 4
		'fiveshows',
		'tenshows',		// 6
		'topfive',
		'thebest',		// 8
		'bookmarks1',
		'bookmarks5', //10
		'bookmarks10',
		'live1',	//12
		'live5',
		'live10',	//14
		'matinee',
	];
	const images = [
		Test,
		Birthday,
		Test, //welcome
		FirstShow,
		ThirdShow,
		FifthShow,
		Test, // 10 shows?
		Test, // topfive
		TopBoard, // #1 leaderboard,
		Bookmarks1,
		Bookmarks5,
		Bookmarks10,
		Live1,
		Live5,
		Live10,
		Matinee,
	];
	const imagesEmbossed = [
		TestEmb, // test
		BirthdayEmb,
		TestEmb, // welcome
		FirstShowEmb,
		ThirdShowEmb,
		FifthShowEmb,
		TestEmb, // 10 shows?
		TestEmb, // topfive
		TopBoardEmb, // #1 leaderboard,
		Bookmarks1Emb,
		Bookmarks5Emb,
		Bookmarks10Emb,
		Live1Emb,
		Live5Emb,
		Live10Emb,
		MatineeEmb,
	];
	const names = [
		'Tester',
		'Birthday 2020',
		'Welcome',
		'First Show',
		'Three Shows',
		'Five Shows',
		'Ten Shows',
		'Top Five',
		'Patron of the Arts',
		'First Bookmark',
		'Piqued Interest',
		'In the Loop',
		'Welcome to Live Mode',
		'Live Mode Expert',
		'Live Mode Veteran',
		'Matinée',
	];
	const descriptions = [
		'A badge awarded purely for testing purposes.',
		'Happy birthday from The Arts at Millersville University!',
		'Sign up for an account with Arts MU Live.',
		'Check In at your first show.',
		'Check In at three shows in one season.',
		'Check In at five shows in one season.',
		'Check In at ten shows in one season.',
		'Break into the top five on the Leaderboard.',
		'Reach #1 on the Leaderboard.',
		'Bookmark a show for the first time.',
		'Bookmark five shows.',
		'Bookmark ten shows.',
		'Experience Live Mode for the first time.',
		'Enter Live Mode five times.',
		'Enter Live Mode for the 10th time.',
		'Attend a show that begins before 5 PM.',
	]
	var formatTimestamp = (t) => {
		return moment(t).format('MMM D YYYY h:mm A');
	}
	return (
		<div className="badge">
			<p className="badge-date">{formatTimestamp(props.badge.timestamp)}</p>
			<picture className="embossed">
				<img 
					src={imagesEmbossed[props.badge.id]} 
					alt={names[props.badge.id]} />
			</picture>
			<picture>
				<img 
					src={images[props.badge.id]} 
					alt={names[props.badge.id]} />
			</picture>
			<p className="badge-name">{names[props.badge.id]}</p>
			{props.description && 
				<p className="badge-desc">{descriptions[props.badge.id]}</p>
			}
		</div>
	);
}

export default BadgeDisplay;