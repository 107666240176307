import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/messaging';
import 'firebase/storage';

// App config data copy/pasted from Firebase console
var firebaseConfig = {
  apiKey: "AIzaSyD80yIuRuuKEzLzAjo5Ep_zkPR8vlr0CWA",
  authDomain: "ware-421.firebaseapp.com",
  databaseURL: "https://ware-421.firebaseio.com",
  projectId: "ware-421",
  storageBucket: "ware-421.appspot.com",
  messagingSenderId: "502701390100",
  appId: "1:502701390100:web:548a85637b21c991ee2a01",
  measurementId: "G-BKQ8FQE2Z3"
};
// Initialize imported firebase object with our app config data
firebase.initializeApp(firebaseConfig);

// Export Firebase's Email Auth Provider
export const provider = new firebase.auth.EmailAuthProvider();
// Export Firebase's Auth object
export const auth = firebase.auth();
// Finally export the Firebase object itself
export default firebase;
