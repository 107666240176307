import React from 'react';
import { Link, withRouter } from 'react-router-dom';

function Footer(props) {
  return (
    <footer id="mainFooter" className={props.liveMode ? 'live' : ''}>
      <button
        id="profileHelp"
        className="cta cta-light button-icon" 
        onClick={(e) => props.history.push('/help')}>
        <span className="text-hidden">Help</span>
      </button>
      <p className="text-center">Millersville University is an Equal Opportunity/Affirmative Action Institution. A member of the Pennsylvania State System of Higher Education.&emsp;
        {!props.liveMode && !props.fileBrowserActive && 
          <span><Link to="/fineprint">Read more of the fine print</Link>.</span>
        }
        {props.fileBrowserActive && 
          <span>Close the File Browser to read more of the fine print.</span>
        }
      </p>
      <p className="text-center">Copyright &copy; {new Date().getFullYear().toString()} The Arts at Millersville University.</p>
    </footer>
  );
}

export default withRouter(Footer);